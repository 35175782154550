<nb-card size="medium">
    <nb-card-header>{{serial}}. Vehicle
        <nb-icon icon="info-outline" style="size: 18px; color: dodgerblue; cursor: pointer"
                 [nbPopoverPlacement]="'right'" [nbPopover]="infoPopup">
        </nb-icon>
    </nb-card-header>
    <nb-card-body class="p-4">
        <ngx-type-ahead (send)="addVehicle($event)"
                        [basePath]="'auth'"
                        [disabled]="!zoneId"
                        [filters]="filters" [model]="{}"
                        [placeholder]="'Search Vehicle here'" [required]="true"  [url]="'vehicle'">

        </ngx-type-ahead>

        <div class="mt-5">
            <nb-list style="max-height: 400px; overflow-y: auto">
                <nb-list-item *ngFor="let vehicle of vehicles; let i = index">
                    <div class="d-flex justify-content-between align-items-center">
                        <nb-user  [name]="vehicle.registration_number" class="cursor">
                        </nb-user>
                        <!--                        <nb-icon (click)="removeVehicle(vehicle.id, i)" class="cursor" icon="close" status="danger"></nb-icon>-->
                    </div>
                </nb-list-item>
            </nb-list>
        </div>

    </nb-card-body>

    <nb-card-footer class="d-flex justify-content-end">
        <button (click)="editVehicle()" nbButton shape="rectangle" status="primary">Add New</button>
    </nb-card-footer>
</nb-card>
