import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Location} from '@angular/common';

@Component({
	selector: 'ngx-permission',
	templateUrl: './permission.component.html',
	styleUrls: ['./permission.component.scss'],
})
export class PermissionComponent implements OnInit {

	@Input()
	userId: string = null;
	permissions: any[] = [];
	@Input()
	userPermissions: any[] = [];

	constructor(private http: DataService, private toaster: ToastService,
				private _location: Location) {
	}

	ngOnInit() {
		this.getPermission().then();
		if (!this.userPermissions)
			this.userPermissions = [];
	}

	async getPermission() {
		try {
			this.permissions = (await this.http.query({}, 'permission', 'auth')).data;
		} catch (e) {

		}
	}

	async save() {

	}

	async cancel() {
		this._location.back();
	}

	checkPermission(id: string, name?: string) {
		try {
			const perm = this.userPermissions.find(p => p.permission === id);
			if (perm) {
				return name ? perm[name] : true;
			}
		} catch (e) {
			return false;
		}
		return false;
	}

	async addPermission(value: boolean, id: string, name?: string) {
		if (name) {

			const data = {__action: 'update', user_id: this.userId, permission_id: id, updates: {}};
			data['updates'][name] = value;
			await this.http.create(data, {}, 'user_permission', 'auth');
		} else {
			if (value) {
				await this.http.create({
					__action: 'add',
					user_id: this.userId,
					permission_id: id,
				}, {}, 'user_permission', 'auth');
				this.userPermissions.push({permission: id, read: false, write: false, delete: false, update: false});
			} else {
				await this.http.create({
					__action: 'remove',
					user_id: this.userId,
					permission_id: id,
				}, {}, 'user_permission', 'auth');
				const perm = this.userPermissions.findIndex(p => p.permission === id);
				this.userPermissions.splice(perm, 1);
			}

		}
	}

	ifNotPermission(id: string) {
			return !this.userPermissions.find(p => p.permission === id);
	}
}
