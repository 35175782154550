import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';
import {DataService} from '../../../@core/utils';
import {Subject} from 'rxjs';


@Component({
    selector: 'ngx-type-ahead',
    templateUrl: './type-ahead.component.html',
    styleUrls: ['./type-ahead.component.scss'],
})
export class TypeAheadComponent implements AfterViewInit {

    spinner: boolean = false;
    data$: Promise<any[]>;
    dataInput$ = new Subject<string>();
    dataLoading = false;
    searchFailed: boolean = true;

    @Input() model: any;
    @Input() multiple: boolean = false;
    @Input() disabled: any;
    @Input() filters: any;
    @Input() required = true;
    @Input() basePath = 'auth';
    @Input() placeholder: string = 'Search here';
    @Input() url: string;
    @Input() text: string = 'name';
    @Input() value: string;
    @Input() searchField: string;
    @Input() notifySearchFail: boolean = false;
    @Input() displayNames: string[] = null;
    @Input() selectedItems = [];
    @Input() autoRouteFc: (id: any) => void;
    @Input() autoType = false;
    @Output() searchTerm: EventEmitter<string> = new EventEmitter();
    @Output() send: EventEmitter<any> = new EventEmitter();
    @Output() failed: EventEmitter<string> = new EventEmitter();
    @Output() cleared: EventEmitter<string> = new EventEmitter();
    @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(private http: DataService, private cd: ChangeDetectorRef) {
    }


ngAfterViewInit() {
    this.cd.detectChanges();
}

    async searchApi(event): Promise<any[]> {

        const query = {};
        if (this.searchField) {
            query[this.searchField] = event;
        } else {
            if (parseInt(event, 10)) {
                query['__phone__contains'] = event;
                query['__mobile_number__contains'] = event;
            } else {
                if (this.url === 'vehicle') {
                    query['__registration_number__contains'] = event;
                } else {
                    query['__name__contains'] = event;
                }
            }
        }
        query['__limit'] = 80;
        for (const i in this.filters) {
            if (this.filters.hasOwnProperty(i)) {
                query[i] = this.filters[i];
            }
        }
        try {

            return (await this.http.query(query, this.url, this.basePath)).data;
        } catch (e) {
            console.error(e);
            return [];
        }

    }

    emitSelected(event) {
        if (typeof event === typeof 'str' || !event) {
            this.failed.emit(event);
            return;
        }
        if (this.autoType) {
            this.autoRouteFc(event.id);
        }

        this.send.emit(event);
        this.searchFailed = true;
        this.onChange.emit(event);
this.cd.detectChanges();
    }

    selectAll() {
        this.spinner = true;
        this.data$.then(async (res: any[]) => {
            for (const r of res) {
                this.send.emit(r);
                await this.delay(500);
            }
            this.spinner = false;
        });
    }

    async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    resultFormatter(x: { name: string, mobile_number: string, registration_number?: string }) {

        try {
            if (this.displayNames && this.displayNames.length) {
                return this.displayNames.map(d => x[d]).join(', ');
            }
            if (x.mobile_number) {
                return x.name + ' - ' + x.mobile_number;
            }
            if (x.registration_number) {
                return x.registration_number;
            }
            return x.name;
        } catch (error) {

        }
    }

    async loadPeople() {
        try {
            this.data$ = this.searchApi(undefined);
        } catch (e) {
        }
        this.dataInput$.pipe(
            distinctUntilChanged(),
            debounceTime(700),
            tap(() => {
                this.dataLoading = true;
            }),
            map(async term => {
                this.spinner = true;
                return this.searchApi(term);
            })).subscribe(async res => {
            this.data$ = res;
            await res;
            this.spinner = false;
            this.dataLoading = false;
        }, () => {
            this.spinner = false;
            this.dataLoading = false;
        });
    }

    inputInit(event) {  // init api call for blank input
        if (event.term === '') {
            try {
                this.data$ = this.searchApi(undefined);
            } catch (e) {
            }
        }
    }
}
