<nav aria-label="page navigation example">
    <ul class="pagination">
        <li class="page-item" (click)="onClickPage(activePage - 1)"><a class="page-link" href="javascript:void(0);">«
            Previous</a></li>
        <li class="page-item" [ngClass]="{'active': activePage === item}" *ngFor="let item of pages"

            (click)="onClickPage(item)"><a class="page-link" href="javascript:void(0);">{{item}}</a></li>
<!--        {{activePage + 1}}-->
        <span><input type="number" [(ngModel)]="activePage"></span>
        <li class="page-item" (click)="onClickPage(activePage + 1)"><a class="page-link" href="javascript:void(0);">Next
            »</a></li>
    </ul>
</nav>
