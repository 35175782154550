import {
	AfterViewInit,
	Component,
	Input,
	OnInit,
	ChangeDetectorRef,
	OnDestroy,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import {NbMenuService, NbSidebarService} from '@nebular/theme';

import {DataService, UserService} from '../../../@core/utils';
import {Auth} from '../../../@core/models';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {MessagingService} from '../../../@core/utils/messaging.service';

@Component({
	selector: 'ngx-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

	@Input() position = 'normal';
	@Input() newNotification: any;

	user: Auth;
	page: number = 1;
	events: any[] = [];
	loading: boolean = false;
	userMenu = [
		{title: 'Account', icon: 'person-outline'},
		{title: 'Logout', icon: 'unlock-outline'},
	];
	placeholders: any[] = [];
	pageSize: number = 50;
	currentApplicationVersion = environment.appVersion;
	private menuSub: Subscription;
	toggleNotificationBody = false;
	notificationData = [];
	currentTab = 'order';
	unSeenNotification = 0;
	firstNotification = true;
	profileIcon: any;
	// loadProfileIcon: boolean = false;

	constructor(private sidebarService: NbSidebarService,
				private menuService: NbMenuService,
				public userService: UserService,
				private router: Router,
				private http: DataService,
				private cd: ChangeDetectorRef,
				private messagingService: MessagingService,
				private userSerive: UserService) {
	}

	ngOnInit() {
		this.menuSub = this.menuService.onItemClick()
			.subscribe(event => {
				if (event.item.title.toString().toLowerCase().trim() === 'logout') {
					this.messagingService.updateToken(this.userService.user.user.id, '').then();
					this.userService.logOut();
				} else if (event.item.title.toString().toLowerCase().trim() === 'account') {
					this.router.navigate(['pages/account-settings/profile']).then( );
				}
			});
		this.loadNext().then();
		this.getNotifications().then();
	}

	async ngAfterViewInit() {
		await this.getCompany();
		this.user = this.userService.user;
		this.userService.user$.subscribe(res => {
			this.user = res;
		});
		this.cd.detectChanges();
	}

	toggleSidebar(): boolean {
		this.sidebarService.toggle(true, 'menu-sidebar');

		return false;
	}

	goToHome() {
		this.menuService.navigateHome();
	}

	updateUrl($event) {
		$event.target.src = 'https://res.cloudinary.com/roadcast/image/upload/v1587716970/synco/Roadcast_ehbev1.png';
	}

	async loadNext() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			this.placeholders = new Array<number>(this.pageSize);
			const events = await this.http.query({
				__order_by: '-created_on',
				__limit: this.pageSize,
			}, 'event', 'event');
			this.loading = false;
			this.events = events.data;
			this.placeholders = [];
		} catch (e) {

		}
	}

	async getNotifications() {
		try {
			this.notificationData = (await this.http.query({
				__limit: 40,
				__order_by: '-created_on',
			}, 'notification', 'auth')).data;
		} catch (e) {
			this.notificationData = [];
		}
	}

	underscoreConvert(string): string {
		return string.replace(/_/g, ' ');
	}

	ngOnDestroy(): void {
		if (this.menuSub) { this.menuSub.unsubscribe(); }
	}

	sameDateCheck(created_on) {
		return moment(created_on).startOf('day').isSame(moment().startOf('day'));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('newNotification') && !this.firstNotification) {
			if (!this.newNotification.created_on) {
					this.newNotification.created_on = new Date();
			}
			this.notificationData.unshift(this.newNotification);
			this.notificationData.pop();
			this.unSeenNotification += 1;
		} else {
			this.firstNotification = false;
		}
	}

	async getCompany() {
		try {
			this.profileIcon = (await this.http.query({}, 'company', 'auth')).data[0].profile_icon;
		} catch (e) {
		}
	}
}
