<nb-card>
    <nb-card-header>{{serial}}. Zones</nb-card-header>
    <nb-card-body class="p-4">
        <ngx-type-ahead (send)="addZone($event)" [disabled]="!partnerId"
                        [filters]="{__only: ['id', 'name'], __active__bool: true}" [model]="{}"
                        [placeholder]="'Search zone here'"
                        [required]="true" [url]="'zone'">

        </ngx-type-ahead>

        <div class="mt-5" class="editzonelist">
            <nb-list>
                <nb-list-item *ngFor="let zone of zones; let i = index">
                    <div class="d-flex justify-content-between align-items-center">
                        <nb-user (click)="editZone(zone.id)" [name]="zone.name" class="cursor">

                        </nb-user>
                        <nb-icon (click)="removeZone(zone.id, i)" class="cursor" icon="close" status="danger"></nb-icon>
                    </div>

                </nb-list-item>
            </nb-list>
        </div>

    </nb-card-body>

    <nb-card-footer class="d-flex justify-content-end">
        <button (click)="editZone()" nbButton shape="rectangle" status="primary">Add New</button>
    </nb-card-footer>
</nb-card>
