import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {NB_AUTH_OPTIONS, NbAuthService, NbLoginComponent} from '@nebular/auth';
import {Router} from '@angular/router';
import {ConstantsService} from '../../@core/utils/constants.service';

@Component({
	selector: 'ngx-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent extends NbLoginComponent {


	constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) options: {}, cd: ChangeDetectorRef, router: Router,
				public constantsService: ConstantsService) {
		super(service, options, cd, router);

	}

    updateLogoURL($event) {
		$event.target.src = this.constantsService.roadcastURL;
    }
}
