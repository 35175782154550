import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Order } from '../../../@core/models';
import {NbComponentSize} from '@nebular/theme/components/component-size';
import {DataService} from '../../../@core/utils';

@Component({
  selector: 'ngx-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {

  @Input()
  textAlign: string;

  @Input()
  header: string;

  @Input()
  body: string;

  @Input()
  bodyMargin: string;

  @Input()
  progressBarLength = 0;

  @Input()
  margin = '';

  @Input()
  progressBarText: string = null;

  @Input()
  progressBarFooterText: string = null;

  @Input()
  cardSize: NbComponentSize = null;

  @Input()
  progressBarColor = '';

  @Input()
  iconName = '';

  @Input()
  iconFill = '';

  @Input()
  headerBg = '';

  @Input()
  bodyBg = '';

  @Input()
  bodyColor = '';

  @Input()
  cardType = '';

  @Input()
	orders: Order;

	@Input()
	order_no: string;
    @Input()
    store_name: string;

	@Input()
	orders_total: any;

	@Input()
	order_status: string;
	@Input()
	statusCode: any;

	@Input()
    external_id: any;

	@Output()
    orderActionEmitter: EventEmitter<any> = new EventEmitter<any>();
    countryCode: any;

  constructor(private http: DataService) { }

  async ngOnInit() {
      await this.paymentCode();
  }
    async paymentCode() {
        try {
            this.countryCode = (await this.http.query({}, 'company', 'auth' )).data[0];
        } catch (e) {
        }
    }
}
