import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';
import {Category} from '../../../@core/models';

@Component({
	selector: 'ngx-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit {

	@Input()
	serial: string = '1';
	@Input()
	categories: Category[] = [];
	@Input()
	rateList: string;

	constructor(private http: DataService, private toaster: ToastService, private router: Router) {
	}

	ngOnInit() {
	}

	async addCategory(event) {
		try {
			await this.http.create({
				__action: 'add',
				category_id: event.id,
				rate_list_id: this.rateList,
			}, {}, 'category_rate_list', 'auth');
			this.categories.push(event);
			this.toaster.showToast('Successfully linked category.', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error adding category ', 'Error', true, e);
		}
	}

	async removeCategory(id: number, index: number) {
		try {
			await this.http.create({
				__action: 'remove',
				category_id: id,
				rate_list_id: this.rateList,
			}, {}, 'category_rate_list', 'auth');
			this.categories.splice(index, 1);
			this.toaster.showToast('Successfully removed category.', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error removing category ', 'Error', true, e);
		}
	}

	editCategory(id?: number) {
		this.router.navigate(['/pages/management/base/category/' + (id ? id.toString(10) : 'new')]);
	}

}
