import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'ngx-rider-info-dialog',
	templateUrl: './rider-info-dialog.component.html',
	styleUrls: ['./rider-info-dialog.component.scss'],
})
export class RiderInfoDialogComponent implements OnInit {

	columns: any[] = [];
	customData: any;
	data: any[] = [];
	displayFn?: (row: any, column: any) => {};

	constructor() {
		this.columns =
			[{
				name: 'name',
				displayName: 'Rider',
			},
				{
					name: 'mobile_number',
					displayName: 'Mobile No.',
				},
				{
					name: 'email',
					displayName: 'Email',
				},
				{
					name: 'app_version',
					displayName: 'App version',
					displayFn: (r => r.device_details ? r.device_details.app_version : ''),
				},
				{
					name: 'is_active',
					displayName: 'Active',
				},
				{
					name: 'manufacturer',
					displayName: ' Manufacturer',
					displayFn: function (row, c) {
						if (row.device_details) {
							return row.device_details.manufacturer;
						} else {
							return '';
						}
					}
				},
				{
					name: 'model',
					displayName: 'Model',
					displayFn: function (row, c) {
						if (row.device_details) {
							return row.device_details.model;
						} else {
							return '';
						}
					},
				},
				{
					name: 'sdk',
					displayName: 'SDK',
					displayFn: function (row, c) {
						if (row.device_details) {
							return row.device_details.sdk;
						} else {
							return '';
						}
					},
				},
				{
					name: 'androidVersion',
					displayName: 'Android Version',
					displayFn: function (row, c) {
						if (row.device_details) {
							return row.device_details.androidVersion;
						} else {
							return '';
						}
					},
				},

			];
	}

	ngOnInit() {
		this.data.push(this.customData);
	}

}
