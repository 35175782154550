<div class="container-fluid m-0 p-0 row d-flex justify-content-between">
	<div class="col-md-5 col-sm-12" style="background: #fff; width: 100vw;">
		<ngb-carousel>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random first slide" class="img-fluid"
					src="../../../assets/images/1.jpg">
				</div>
				<!--      <div class="carousel-caption">-->
				<!--          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--             blanditiis quaerat porro ut,-->
				<!--             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--        </div>-->
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random second slide" class="img-fluid"
						 src="../../../assets/images/2.jpg">
				</div>
				<!--        <div class="carousel-caption">-->
				<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--           blanditiis quaerat porro ut,-->
				<!--           esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--      </div>-->
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random third slide" class="img-fluid"
						 src="../../../assets/images/3.jpg">
				</div>
				<!--      <div class="carousel-caption">-->
				<!--          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--             blanditiis quaerat porro ut,-->
				<!--             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--        </div>-->
			</ng-template>
<!--			<ng-template ngbSlide>-->
<!--				<div class="picsum-img-wrapper">-->
<!--					<img alt="Random third slide" class="img-fluid"-->
<!--						 src="../../../assets/images/Artboard 30@4x.jpg">-->
<!--				</div>-->
<!--				&lt;!&ndash;      <div class="carousel-caption">&ndash;&gt;-->
<!--				&lt;!&ndash;          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,&ndash;&gt;-->
<!--				&lt;!&ndash;             blanditiis quaerat porro ut,&ndash;&gt;-->
<!--				&lt;!&ndash;             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>&ndash;&gt;-->
<!--				&lt;!&ndash;        </div>&ndash;&gt;-->
<!--			</ng-template>-->
			<!-- <ol class="carousel-indicators">
			  <li id="ngb-slide-0" class=""></li>
			  <li id="ngb-slide-1" class=""></li>
			  <li id="ngb-slide-2" class="active"></li>
			</ol> -->
		</ngb-carousel>

	</div>


	<div class="col-md-7 col-sm-12" style="background: #F0F5F8;">
		<div class="d-flex justify-content-center mx-auto" style="height: 40px; margin-top: 100px;">
			<img alt="Roadcast Logo" height="50px" [src]="constantsService.logoURL" (error)="updateLogoURL($event)">
		</div>


		<form #resetPassForm="ngForm" (ngSubmit)="resetPass()" aria-labelledby="title"
			  style="display: flex;justify-content: center;align-items: center;margin-top: 50px;">
			<div class="col-md-4 col-sm-12">

				<h1 class="title" id="title">Change password</h1>
				<p class="sub-title">Please set a new password</p>

				<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
					<p class="alert-title"><b>Oh snap!</b></p>
					<ul class="alert-message-list" style="list-style-type: none; padding-left: 0px;">
						<li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
					</ul>
				</nb-alert>

				<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
					<p class="alert-title"><b>Hooray!</b></p>
					<ul class="alert-message-list" style="list-style-type: none; padding-left: 0px;">
						<li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
					</ul>
				</nb-alert>

				<div class="form-group">
					<label for="input-password">New Password:</label>
					<input #password="ngModel"
						   [(ngModel)]="user.password"
						   [attr.aria-invalid]="password.invalid && password.touched ? true : null"
						   [maxlength]="getConfigValue('forms.validation.password.maxLength')"
						   [minlength]="getConfigValue('forms.validation.password.minLength')"
						   [required]="getConfigValue('forms.validation.password.required')"
						   [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
						   autofocus
						   class="first"
						   fieldSize="medium"
						   fullWidth
						   id="input-password"
						   name="password"
						   nbInput
						   placeholder="New Password"
						   shape="semi-round"
						   size="small"
						   type="password">
					<ng-container *ngIf="password.invalid && password.touched">
						<p *ngIf="password.errors?.required" class="caption status-danger">
							Password is required!
						</p>
						<p *ngIf="password.errors?.minlength || password.errors?.maxlength"
						   class="caption status-danger">
							Password should contains
							from {{getConfigValue('forms.validation.password.minLength')}}
							to {{getConfigValue('forms.validation.password.maxLength')}}
							characters
						</p>
					</ng-container>
				</div>

				<div class="form-group">
					<label class="label" for="input-re-password">Confirm Password:</label>
					<input #rePass="ngModel"
						   [(ngModel)]="user.confirmPassword"
						   [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null"
						   [required]="getConfigValue('forms.validation.password.required')"
						   [status]="rePass.touched
                       ? (rePass.invalid || password.value != rePass.value ? 'danger' : 'success')
                       : ''"
						   class="last"
						   fieldSize="medium"
						   fullWidth
						   id="input-re-password"
						   name="rePass"
						   nbInput
						   placeholder="Confirm Password"
						   shape="semi-round"
						   size="small"
						   type="password">
					<ng-container *ngIf="rePass.touched">
						<p *ngIf="rePass.invalid && rePass.errors?.required" class="caption status-danger">
							Password confirmation is required!
						</p>
						<p *ngIf="password.value != rePass.value && !rePass.errors?.required"
						   class="caption status-danger">
							Password does not match the confirm password.
						</p>
					</ng-container>
				</div>

				<button [class.btn-pulse]="submitted"
						[disabled]="submitted || !resetPassForm.valid"
						fullWidth
						nbButton
						shape="semi-round"
						size="small"
						status="primary">
					Change password
				</button>
				<br> <br>
				<section aria-label="Sign in or sign up" class="sign-in-or-up" style="text-align: center;">
					<p><a class="text-link font-weight-bold" routerLink="../login">Back to Log In</a></p>
					<p><a class="text-link font-weight-bold" routerLink="../register">Register</a></p>
				</section>
			</div>
		</form>


	</div>
</div>
