<nb-card>
	<nb-card-header>1. Basic Details</nb-card-header>
	<nb-card-body class="p-4">
		<form #authForm=ngForm class="form form-horizontal was-validated">
			<!--  <div class="form-group">-->
			<!--    <label>Name</label>-->
			<!--    <input #name=ngModel [(ngModel)]="auth.name" fullWidth minlength="4" name="name" nbInput required="true"-->
			<!--           shape="rectangle" status="primary" type="text">-->

			<!--    <div *ngIf="name.invalid && (name.dirty || name.touched)"-->
			<!--         class="alert alert-danger mt-3">-->

			<!--      <div *ngIf="name.errors.required">-->
			<!--        Name is required.-->
			<!--      </div>-->
			<!--      <div *ngIf="name.errors.minlength">-->
			<!--        Name must be at least 4 characters long.-->
			<!--      </div>-->

			<!--    </div>-->

			<!--  </div>-->

			<div class="form-group">
				<label>Email</label>
				<!--  chrome field freeze bug on 8 August 2020 -->
				<input style="height: 0; width: 0; border: 0; background-color: transparent" type="email" name="email"/>
				<input style="height: 0; width: 0; border: 0; background-color: transparent" type="password" name="password"/>
				<input style="height: 0; width: 0; border: 0; background-color: transparent" type="text" name="fakeusernameremembered"/>
				<input style="height: 0; width: 0; border: 0; background-color: transparent" type="password" name="fakepasswordremembered"/>
				<!--  chrome field freeze bug on 8 August 2020 -->

				<input #authEmail=ngModel [(ngModel)]="auth.email" fullWidth nbInput name="correctemail"
					   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required shape="rectangle"
					   class="form-control" (ngModelChange)="auth.email = auth.email.toLowerCase()"
					   type="email">


				<div *ngIf="authEmail.invalid && (authEmail.dirty || authEmail.touched)"
					 class="alert alert-danger mt-3">

					<div *ngIf="authEmail.errors.required">
						email is required.
					</div>
					<div *ngIf="authEmail.errors.pattern">
						email must be of the form example@example.com.
					</div>

				</div>

			</div>

<!--			<div class="form-group">-->
<!--				<label>Mobile #</label>-->
<!--				<input #authPhone=ngModel [(ngModel)]="auth.mobile_number" fullWidth name="phone" nbInput-->
<!--					   pattern="[123456789][0-9]{6,10}" required="true" shape="rectangle"-->
<!--					   status="primary" type="tel">-->


<!--				<div *ngIf="authPhone.invalid && (authPhone.dirty || authPhone.touched)"-->
<!--					 class="alert alert-danger mt-3">-->

<!--					<div *ngIf="authPhone.errors.required">-->
<!--						phone is required.-->
<!--					</div>-->
<!--					<div *ngIf="authPhone.errors.pattern">-->
<!--						phone must be of 7 to 10 numbers.-->
<!--					</div>-->

<!--				</div>-->
<!--			</div>-->
			<div class="form-group">
				<label>Password</label>
				<input #authPassword=ngModel [(ngModel)]="password" autocomplete="off" fullWidth minlength="6"
					    nbInput shape="rectangle"  name="correctpassword"
					   class="form-control" type="password">


				<div *ngIf="authPassword.invalid && (authPassword.dirty || authPassword.touched)"
					 class="alert alert-danger mt-3">

					<div *ngIf="authPassword.errors.minlength">
						password must be 6 or more characters.
					</div>

				</div>
			</div>

		</form>
	</nb-card-body>

	<nb-card-footer>
		<div class="d-flex justify-content-end">
			<button (click)="cancel()" class="mr-5" nbButton status="danger">CANCEL</button>
			<button (click)="save()" [disabled]="authForm.form.invalid" nbButton status="primary">SAVE</button>
		</div>
	</nb-card-footer>
</nb-card>
