<div class="mt-4 mb-3 d-flex align-items-end justify-content-between flex-nowrap">

	<div *ngIf="addNew">
		<button (click)="edit()" nbButton shape="rectangle" size="small" status="primary">Create New
		</button>&nbsp;
		<button *ngIf="excelUpload" (click)="dialogRef = nbDialogService.open(addRider)" nbButton shape="rectangle" size="small" status="success">
			Upload as excel
		</button>
	</div>
	<div *ngIf="dateFilter" class="d-flex align-items-center">
		<ng-container *ngIf="isInvoice; else defaultBlock" >
			<ng-container *ngIf="singleDay; else multi">

				<mbsc-datepicker
						[controls]="['calender']"
						select="range"
						inputStyle="outline"
						display="anchored"
						[placeholder]="'Pick Date Range'"
						(onChange)="invoiceDateChange($event)"
						[touchUi]="true">
				</mbsc-datepicker>
			</ng-container>
			<ng-template #multi>

				<mbsc-datepicker
						(onChange)="changeInvoiceRange($event)"
						[controls]="['calendar']"
						[placeholder]="'Pick Date Range '"
						[touchUi]="true"
						display="anchored"
						inputStyle="outline"
						select="range">
				</mbsc-datepicker>
			</ng-template>
		</ng-container>
		<ng-template #defaultBlock>
			<ng-container *ngIf="singleDay; else multi">
				<mbsc-datepicker
						[controls]="['calender']"
						select="range"
						inputStyle="outline"
						display="anchored"
						[placeholder]="'Pick Date Range'"
						(onChange)="dateChange($event)"
						[touchUi]="true">
				</mbsc-datepicker>
			</ng-container>
			<ng-template #multi>
<!--				rider date filter-->
				<mbsc-datepicker
                        *ngIf="!timeFilter"
                        (onChange)="changeRange($event)"
                        [controls]="['calendar']"
                        [placeholder]="'Pick Date Range'"
                        [touchUi]="true"
						display="anchored"
                        inputStyle="outline"
                        select="range">
				</mbsc-datepicker>
                <!--                 <input *ngIf="timeFilter" (dateTimeChange)="setDateTime($event)" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1"-->
                <!--                        class="input-group" fullWidth nbInput [selectMode]="'range'"-->
                <!--                        [placeholder]="orderDownload ? selectedDateTime : 'Date Time'" size="medium">-->
<!--				order-report-->
				<mbsc-datepicker
						*ngIf="timeFilter"
						(onChange)="setDateTime($event)"
						[controls]="['calendar', 'time']"
						[placeholder]="'Pick Date Range'"
						[touchUi]="true"
						display="anchored"
						inputStyle="outline"
						select="range">
				</mbsc-datepicker>
                <owl-date-time #dt1></owl-date-time>
			</ng-template>
		</ng-template>
	</div>
	<div *ngIf="monthFilter" class="d-flex align-items-center">

		<mbsc-datepicker
                select="range"
                inputStyle="outline"
                [controls]="['calendar']"
                [placeholder]="'Pick Month'"
                (onChange)="selectMonth($event)"
				display="anchored"
                dateFormat="MM/YYYY"
                [touchUi]="true">
		</mbsc-datepicker>
	</div>
	<div *ngIf="multipleZone" class="d-flex align-items-center">
		<ngx-type-ahead (cleared)="multiZoneFilter($event)"
						(send)="multiZoneFilter($event)"
						[filters]="{__active__bool: 'true'}"
						basePath="auth"
						[multiple]="true"
						placeholder="Select Multiple Zone"
						url="zone">
		</ngx-type-ahead>
	</div>

	<div class="d-flex justify-content-between">
		<div>
			<ng-container *ngFor="let filter of filters">
				<nb-checkbox #checked (change)="applyFilter(filter, checked?.checked)" class="mr-2">
					{{filter.name}}
				</nb-checkbox>
			</ng-container>
		</div>
	</div>
	<ng-container *ngIf="searchFields.length">
		<button #pop1="ngbPopover" [autoClose]="false" [ngbPopover]="filterTemp" [disablePopover]="closePopup"
				[placement]="'bottom-right'" [popoverClass]="'pop'" class="bg-light radius-6"
				nbButton outline (click)="closePopup=!closePopup"
				shape="rectangle" status="primary">
			<nb-icon icon="options-2-outline"></nb-icon>
		</button>
		<ng-template #filterTemp>
			<div class="p-3 row d-flex align-items-center flex-column">
				<ng-container *ngFor="let field of searchFields">
					<ng-container *ngIf="field?.type === 'typeahead';else noTypeAhead">
						<div class="col-12 mb-3">
							<ngx-type-ahead (send)="field.term = $event.id;  this.fieldName = $event.name" [filters]="field.filters"
											[placeholder]="fieldName && field.term ? fieldName : field.name"
											(cleared)="fieldName=''"
											[url]="field?.path"
											[basePath]="field.base ? field.base : 'auth'">
							</ngx-type-ahead>
						</div>
					</ng-container>
					<ng-template #noTypeAhead>
						<div class="col-12 mb-3" *ngIf="field.type !== 'date' && field.type !== 'date-range-start' &&
                        field.type !== 'date-range-end'">
							<input [(ngModel)]="field.term" [placeholder]="field.name" class="col-12"
								   nbInput shape="rectangle" size="tiny" type="text">
						</div>
						<div class="col-12 mb-3" *ngIf="field.type === 'date'">
							<input [(ngModel)]="field.term" [placeholder]="field.name" class="col-12"
								   nbInput shape="rectangle" size="tiny" type="date">
						</div>
						<div class="col-12 mb-3" *ngIf="field.type === 'date-range-start'">
							<mbsc-datepicker
									(onChange)="filterDateRange($event)"
									[controls]="['calendar']"
									[placeholder]="field.name"
									[(ngModel)]="dateFilterRange"
									[touchUi]="true"
									display="anchored"
									inputStyle="outline"
									select="range">
							</mbsc-datepicker>
						</div>
						<div class="col-12 mb-3" *ngIf="field.type === 'toggle'">
							<nb-checkbox [(checked)]="field.term">{{field.name}}</nb-checkbox>
						</div>
					</ng-template>
				</ng-container>
				<div class="mt-3">
					<button (click)="search(true);" class="mr-3" nbButton size="small"
							status="danger">Clear
					</button>
					<button (click)="search()" nbButton size="small" status="success"
					>Apply
					</button>
				</div>
			</div>
		</ng-template>
	</ng-container>
</div>
<div [nbSpinnerMessage]="'Fetching data. Please Wait'" [nbSpinnerStatus]="'danger'" [nbSpinner]="loading"
	 class="table-responsive" style="border-radius: 25px" nbSpinnerSize="large">
	<table class="table table-bordered "  >
		<thead class="">
        <tr class="font-weight-bold">
            <th scope="col" style="white-space: nowrap">Sr.No</th>
            <th *ngFor="let column of columns" scope="col" style="white-space: nowrap">{{ column?.displayName }}</th>
            <th *ngIf="editIcon" scope="col" style="white-space: nowrap">Edit</th>
            <th *ngIf="infoIcon" scope="col" style="white-space: nowrap">View</th>
            <th *ngIf="disableIcon" scope="col" style="white-space: nowrap">Enable</th>
            <th *ngIf="deleteIcon" scope="col" style="white-space: nowrap">Delete</th>
            <th *ngIf="folderIcon" scope="col" style="white-space: nowrap">Download</th>
        </tr>
		</thead>
		<tbody class="fixed-height">
        <ng-container *ngFor="let row of data  let i =index ">
            <tr>
                <td *ngIf="totalPages">{{(page - 1) * 10 + i + 1}} </td>
                <ng-container *ngFor="let column of columns">
                    <td [innerHTML]="column.displayFn ? column.displayFn(row, column.name) :
                        (column.name === 'distance' ? (row[column.name]/1).toFixed(2) : row[column.name])"></td>
                </ng-container>
                <td *ngIf="editIcon">
                    <div class="icon cursor-pointer">
                        <a>
                            <i class="eva-icon-container" style="cursor: pointer">
                                <nb-icon (click)="edit(row.id)" [icon]="'edit-outline'"></nb-icon>
                            </i>
                        </a>
                    </div>
                </td>

                <td *ngIf="infoIcon">
                    <div (click)="view.emit(row)" class="icon cursor-pointer">
                        <i class="eva-icon-container" style="cursor: pointer">
                            <nb-icon [icon]="'info-outline'"></nb-icon>
                        </i>
                    </div>
                </td>
                <td *ngIf="folderIcon">
                    <div (click)="download_pdf.emit(row)" class="icon cursor-pointer">
                        <i class="eva-icon-container">
                            <nb-icon [icon]="'download'"></nb-icon>
                        </i>
                    </div>
                </td>
                <td *ngIf="disableIcon">
                    <div (click)="disable(row, disableColumn, confirmDialog)" class="icon cursor-pointer">
                        <i class="eva-icon-container">
                            <ng-container *ngIf="row[disableColumn]; else disabled">
                                <nb-icon [icon]="'toggle-right-outline'"
                                         [options]="{ animationType: 'pulse', fill: 'green', width: 24, height: 24 }">
                                </nb-icon>
                            </ng-container>
                            <ng-template #disabled>
                                <nb-icon [icon]="'toggle-left-outline'"
                                         [options]="{ animationType: 'pulse', fill: 'red', width: 24, height: 24 }">
                                </nb-icon>
                            </ng-template>
                        </i>
                    </div>
                </td>
                <td *ngIf="deleteIcon">
                    <div (click)="delete(row)" class="icon cursor-pointer" style="cursor: pointer;">
                        <i class="eva-icon-container">
                            <nb-icon [icon]="'trash-2-outline'"
                                     [options]="{ animationType: 'pulse', fill: 'red', width: 24, height: 24 }">
                            </nb-icon>
                        </i>
                    </div>
                </td>

            </tr>
        </ng-container>
		</tbody>
	</table>
	<table *ngIf="footer" class="table table-footer">
		<thead class="thead-light">
        <tr>
            <th class="p-2">
                <button (click)="saveAs()" *ngIf="canDownload" [shape]="'rectangle'" [status]="'info'" nbButton
                        shape="rectangle"
                        size="small">DOWNLOAD
                </button>
                <button (click)="orderReportDownload()" *ngIf="orderDownload" [shape]="'rectangle'" [status]="'info'"
                        nbButton
                        shape="rectangle"
                        size="small">DOWNLOAD
                </button>
            </th>
            <th *ngIf="totalPages > 1" class="p-2 d-flex justify-content-between align-items-end">
                <div>&nbsp;</div>
                <div [ngClass]="page === 1? 'disabled':''" class="icon">
                    <i (click)="previousPage()" class="eva-icon-container previous-page">
                        <nb-icon icon="arrow-left-outline"></nb-icon>
                    </i>
                </div>
                <div>
                    PAGE
                    <input [(ngModel)]="currPage" [max]="totalPages" [value]="page" class="ml-3 mr-1" min="1"
                           nbInput type="number">
                    (max: {{totalPages}})
                    <button (click)="setPage()" [shape]="'rectangle'" class="ml-3" nbButton size="small">GO</button>
                </div>
                <div [ngClass]="page === totalPages? 'disabled':''" class="icon">
                    <i (click)="nextPage()" class="eva-icon-container next-page">
                        <nb-icon icon="arrow-right-outline"></nb-icon>
                    </i>
                </div>
                <div>&nbsp;
                </div>
            </th>
            <th class="p-2">
            </th>
        </tr>
		</thead>
	</table>
</div>
<ng-template #addRider>
	<div style="padding: 10px;min-height: 150px;background: whitesmoke">
		<label>Upload your excel file</label><br>
		<a style="color: blue" href="/assets/rider_upload_format.xlsx">Download excel sample format for uploading riders</a>  &nbsp; &nbsp;
		<input #target (change)="fileUpload(target)" type="file">
		<div class="w3-border w3-margin-top">
			<ng2-smart-table
					[settings]="tableSettings"
					[source]="tableDataSource" >
			</ng2-smart-table>
		</div>
		<div class="w3-margin-top w3-right">
			<button nbButton size="tiny" status="primary" class="w3-right" (click)="uploadFile()">Upload</button>
            <button (click)="closeDialog()" class="w3-right" nbButton size="tiny" status="danger" style="float: right">
				Cancel</button>&nbsp;
		</div>
	</div>
</ng-template>
<ng-template #confirmDialog let-ref=dialogRef let-data>
	<nb-card style="height: 20vh;width: 20vw;overflow: auto">
		<nb-card-body class="d-flex align-items-center" style="overflow: hidden">
			<Strong>Are you sure you want to {{data.toggle ? 'enable' : 'disable'}}?</Strong>
		</nb-card-body>
		<nb-card-footer class="d-flex justify-content-between">
            <button (click)="ref.close(false)" class="m3" nbButton size="small" status="danger">No</button>
			<button nbButton size="small" status="primary" class="m3" (click)="ref.close(true)">Yes</button>
		</nb-card-footer>
	</nb-card>
</ng-template>
