import {Component, Input} from '@angular/core';

@Component({
	selector: 'ngx-one-column-layout',
	styleUrls: ['./one-column.layout.scss'],
	template: `
    <nb-layout >
      <nb-layout-header fixed>
        <ngx-header [newNotification]="newNotification"></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start state="compacted" fixed="false">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column style="padding: 16px">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <!--<nb-layout-footer fixed>-->
        <!--<ngx-footer></ngx-footer>-->
      <!--</nb-layout-footer>-->
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
	@Input() newNotification: any;
}
