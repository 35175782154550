<nb-card>
	<nb-card-header>{{serial}}. Map View</nb-card-header>
	<nb-card-body class="p-4">
		<div
			(leafletMapReady)="onMapReady($event)"
			[leafletOptions]="options"
			leaflet [ngStyle]="minHeight ? {minHeight: minHeight} : {minHeight: '500px'}">

            <div *ngIf="!showAddOnClearAllBool" (leafletDrawCreated)="createdZone($event)"
                 [leafletDrawOptions]="drawOptions"
                 leafletDraw>
			</div>
			<div *ngIf="showAddOnClearAllBool" (leafletDrawCreated)="createdZone($event)"
				 [leafletDrawOptions]="drawOptionsWithEdit"
				 leafletDraw>
			</div>
		</div>
		<div *ngIf="showSearch" class="row map-search">
			<ngx-google-search (emitAddress)="search($event)" [height]="40" [inputAddress]="'Search here'">
			</ngx-google-search>
		</div>

	</nb-card-body>
</nb-card>
