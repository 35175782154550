<nb-card [nbSpinner]="loading" [nbSpinnerMessage]="spinnerMessage" nbSpinnerStatus="primary">
    <nb-card-header>{{serial}}. Approval</nb-card-header>
    <nb-card-body class="p-4"  *ngIf="approval">
        <table class="table">
            <tr>
                <td>Current Status</td>
                <th [innerText]="approval.status"></th>
            </tr>
            <tr>
                <td colspan="2">
                    <p><label class="required">Remarks</label></p>
                    <label style="width: 100%">
                        <textarea [(ngModel)]="notes" nbInput  placeholder="Input Remarks Here" style="width: 100%"></textarea>
                    </label>
                </td>
            </tr>
            <tr>
                <td>
                    <button (click)="approve()" [disabled]="approval.status === 'APPROVED' || approval.status === 'REJECTED' || notes == ''" nbButton shape="rectangle" size="small"
                            status="success">Approve
                    </button>
                </td>
                <td>
                    <button (click)="reject()" [disabled]="approval.status === 'APPROVED' || approval.status === 'REJECTED' || notes == ''" nbButton shape="rectangle" size="small"
                            status="danger">Reject</button>
                </td>
            </tr>
        </table>
    </nb-card-body>
    <nb-card-footer *ngIf="approvalList.length > 0">
        <b>History</b>
        <table class="table">
            <tr>
                <th>Modifier</th>
                <th>Status</th>
                <th>Notes</th>
            </tr>
            <tr *ngFor="let row of approvalList">
                <td [innerText]="row.user.email"></td>
                <td [innerText]="row.status"></td>
                <td [innerText]="row.notes"></td>
            </tr>
        </table>
    </nb-card-footer>
</nb-card>
