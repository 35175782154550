<div class="container-fluid m-0 p-0 row d-flex justify-content-between">
	<div class="col-md-6 col-sm-12" style="background: #67c5b9; width: 100%;padding: 0!important;">

		<ngb-carousel>
<!--			<ng-template ngbSlide>-->
<!--				<div class="picsum-img-wrapper">-->
<!--					<img alt="Random first slide" class="img-fluid"-->
<!--						 src="../../../assets/images/Artboard 27@4x.jpg">-->
<!--				</div>-->
<!--				&lt;!&ndash;      <div class="carousel-caption">&ndash;&gt;-->
<!--				&lt;!&ndash;          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,&ndash;&gt;-->
<!--				&lt;!&ndash;             blanditiis quaerat porro ut,&ndash;&gt;-->
<!--				&lt;!&ndash;             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>&ndash;&gt;-->
<!--				&lt;!&ndash;        </div>&ndash;&gt;-->
<!--			</ng-template>-->
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random second slide" class="img-fluid"
						 src="../../../assets/images/1.jpg">
				</div>
				<!--        <div class="carousel-caption">-->
				<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--           blanditiis quaerat porro ut,-->
				<!--           esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--      </div>-->
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random third slide" class="img-fluid"
						 src="../../../assets/images/2.jpg">
				</div>
				<!--      <div class="carousel-caption">-->
				<!--          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--             blanditiis quaerat porro ut,-->
				<!--             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--        </div>-->
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random third slide" class="img-fluid"
						 src="../../../assets/images/3.jpg">
				</div>
				<!--      <div class="carousel-caption">-->
				<!--          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--             blanditiis quaerat porro ut,-->
				<!--             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--        </div>-->
			</ng-template>
			<!-- <ol class="carousel-indicators">
			  <li id="ngb-slide-0" class=""></li>
			  <li id="ngb-slide-1" class=""></li>
			  <li id="ngb-slide-2" class="active"></li>
			</ol> -->
		</ngb-carousel>

	</div>


	<div class="col-md-6 col-sm-12" style="background: #F0F5F8;">
		<div class="d-flex justify-content-center mx-auto" style="height: 40px; margin-top: 100px;">
			<img alt="Roadcast Logo" height="50px" [src]="constantsService.logoURL" (error)="updateLogoURL($event)">
		</div>
		<form #form="ngForm" (ngSubmit)="login()"
			  style="display: flex;justify-content: center;align-items: center;margin-top: 50px;">
			<div class="col-md-4 col-sm-12">

				<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
					<p class="alert-title"><b>Oh snap!</b></p>
					<ul class="alert-message-list" style="list-style-type: none; padding-left: 0;">
						<li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
					</ul>
				</nb-alert>

				<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
					<p class="alert-title"><b>Hooray!</b></p>
					<ul class="alert-message-list" style="list-style-type: none; padding-left: 0;">
						<li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
					</ul>
				</nb-alert>

				<div class="form-group">
					<label for="input-email">Username</label>
					<input #email="ngModel" [(ngModel)]="user.email"
						   [attr.aria-invalid]="email.invalid && email.touched ? true : null"
						   [required]="getConfigValue('forms.validation.email.required')"
						   [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : null" autofocus
						   fieldSize="medium" (ngModelChange)="user.email = user.email.toLowerCase()"
						   fullWidth
						   id="input-email"
						   name="email"
						   nbInput
						   pattern=".+@.+\..+"
						   placeholder="Username"
						   shape="semi-round"
						   size="small"
						   type="text">
					<ng-container *ngIf="email.invalid && email.touched">
						<p *ngIf="email.errors?.required" class="caption status-danger">
							Username is required!
						</p>
						<p *ngIf="email.errors?.pattern" class="caption status-danger">
							Username should be the real one!
						</p>
					</ng-container>
				</div>

				<div class="form-group">
					<label for="input-password">Password</label>
					<input #password="ngModel" [(ngModel)]="user.password"
						   [attr.aria-invalid]="password.invalid && password.touched ? true : null"
						   [maxlength]="getConfigValue('forms.validation.password.maxLength')"
						   [minlength]="getConfigValue('forms.validation.password.minLength')"
						   [required]="getConfigValue('forms.validation.password.required')"
						   [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : null"
						   fieldSize="medium"
						   fullWidth
						   id="input-password"
						   name="password"
						   nbInput
						   placeholder="Password"
						   shape="semi-round"
						   size="small"
						   type="password">
					<ng-container *ngIf="password.invalid && password.touched ">
						<p *ngIf="password.errors?.required" class="caption status-danger">
							Password is required!
						</p>
						<p *ngIf="password.errors?.minlength || password.errors?.maxlength"
						   class="caption status-danger">
							Password should contain
							from {{ getConfigValue('forms.validation.password.minLength') }}
							to {{ getConfigValue('forms.validation.password.maxLength') }}
							characters
						</p>
					</ng-container>
				</div>
				<div class="form-group">
					<nb-checkbox *ngIf="rememberMe" [(ngModel)]="user['rememberMe']" name="rememberMe">Remember me
					</nb-checkbox>
				</div>
				<div class="form-group">
					<button [class.btn-pulse]="submitted"
							[disabled]="submitted || !form.valid"
							fullWidth
							nbButton
							shape="semi-round"
							size="small" status="primary"> Log In
					</button>
				</div>

				<div style="text-align: center;"><a class="forgot-password caption-2" routerLink="../request-password">Forgot
					password?</a></div>

				<div class="d-flex justify-content-center align-content-center align-items-center"
					 style="width: inherit; display: flex; margin-top: 100px; flex-direction: column;">
					<p>New User? Create a new account</p>
					<button class="text-link" fullWidth nbButton routerLink="../register" shape="semi-round"
							size="small"
							status="info"
							type="submit">Sign up
					</button>
				</div>
			</div>
		</form>


	</div>
</div>
