import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NbDateService} from '@nebular/theme';

@Component({
  selector: 'ngx-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css'],
})
export class DateFilterComponent implements OnInit {
  @Input() placeholder: string;
  @Input() output;
  @Input() dateFormat: string;
  @Input() inputStyle;
  @Input() selectMultiple = false;
  @Input() controls = [];
  @Input() maxDate;
  @Input() minDate;
  @Input() select;
  @Input() display;
  @Output() changeDate: EventEmitter<any> = new EventEmitter<any>();
  selected: any;
  oldValue: Date[] = [];
  constructor(protected dateService: NbDateService<Date>) {
    this.maxDate = this.dateService.today();
    this.minDate = this.dateService.addDay(this.dateService.today(), -360);
  }
  ngOnInit(): void {
  }


  selectDateRange(event: any) {
    if (this.oldValue[1] && this.oldValue[1].getDate() === event.value[1].getDate()) {
      this.selected[1].setHours(0, 0, 0, 0);
    }
    this.oldValue = event.value;
    this.changeDate.emit(this.oldValue);
  }
}
