import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.css'],
})
export class VehicleListComponent {

  @Input() serial: string = '1';
  @Input() zoneId: string;

  vehicles: any[] = [];
  filters: any;
  infoPopup = 'If unable to add Vehicle, create zone first (step 1)';

  constructor(private http: DataService, private toaster: ToastService, private router: Router, private cd: ChangeDetectorRef) {
  }


  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    this.filters = {__zones_id__ne: this.zoneId};
    if (this.zoneId) {
      this.getVehicles().then();
    }
    this.cd.detectChanges();
  }


  async getVehicles() {
    try {
      const vehicles = await this.http.query({__zone_id__equal: this.zoneId}, 'vehicle', 'auth');
      if (vehicles.data && vehicles.data.length) {
        this.vehicles = vehicles.data;
      } else {
        this.vehicles = [];
      }
    } catch (e) {
    }
  }


  editVehicle(id?: number) {
    this.router.navigate(['/pages/management/base/vehicle-detail/' + (id ? id : 'new')]);
  }



  async addVehicle(event) {
  }


}
