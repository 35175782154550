import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MapService} from '../../../pages/map/map.service';
import {DataService} from '../../../@core/utils';
import * as moment from 'moment';
import {RoutePositionService} from '../../../pages/route-management/route-position.service';
import {interval} from 'rxjs';
import {NbDateService} from '@nebular/theme';
@Component({
  selector: 'ngx-live-tracking',
  templateUrl: './live-tracking.component.html',
  styleUrls: ['./live-tracking.component.scss'],
})
export class LiveTrackingComponent implements OnInit {
  @Input() device: any;
  replaySubsriber: any;
  address: string;
  coords: any[] = [];
  showTrack = true;
  replayStatus: boolean = false;
  playReplay: boolean = false;
  @Input() sliderValue: number = 0;
  @Output() sliderPath: EventEmitter<any> = new EventEmitter();
  @Output() sendPath: EventEmitter<any[]> = new EventEmitter();
  @Output() sendTouchPoints: EventEmitter<any> = new EventEmitter();
  @Output() replayPath: EventEmitter<any> = new EventEmitter();
  selectedCustomRange: any = [];
  distance: string;
  moment: any = moment;
  defaultFromDate: Date;
  defaultToDate: Date;
  private maxDateRange: Date;
  constructor(private mapService: MapService, private http: DataService, private routePosition: RoutePositionService,
              protected dateService: NbDateService<Date>) {
    this.maxDateRange = this.dateService.today();
    this.defaultFromDate = this.dateService.today();
    this.defaultFromDate.setHours(0, 0, 0);
    this.defaultToDate = this.dateService.today();
    this.defaultToDate.setHours(23, 59, 59);
  }

  ngOnInit(): void {
    this.selectedCustomRange.push(new Date(this.defaultFromDate));
    this.selectedCustomRange.push(new Date(this.defaultToDate));
  }

  async selectedDateRange(event) {
    if (event && event.value[0] && event.value[1]) {
      this.selectedCustomRange[0] = event.value[0].toJSON();
      this.selectedCustomRange[1] = event.value[1].toJSON();
    }

  }

  async track() {
    this.showTrack = false;
    const changeslider = () => {
      this.sliderValue = 0;
    };
    changeslider();
    let res;
    let coords;
    try {

      if (!this.mapService.selectVehicle) {
        res = await this.http.query({
          __include: ['course'],
          __rider_id__equal: this.device.id,
          __fix_time__datetime_btw: [moment(this.selectedCustomRange[0]).toJSON(), moment(this.selectedCustomRange[1]).toJSON()],
        }, 'position', 'event');
        coords = res.data.filter(d => d.current_location.latitude && d.current_location.longitude).map(d => {
          return {
            'coords': [d.current_location.latitude, d.current_location.longitude],
            'details': d,
          };
        });

      } else {
        coords = await this.routePosition.getVehicleById(this.selectedCustomRange);

      }
      this.coords = coords;
      if (this.coords.length) {
        this.replayStatus = true;
      }
      this.sendPath.emit(coords);
    } catch (e) {
      this.sendPath.emit([]);
    }
  }
  stopSlider() {
    this.coords = [];
    this.showTrack = true;
    this.sendPath.emit([]);
    if (this.replaySubsriber) {
      this.replaySubsriber.unsubscribe();
    }
    this.replay('stop');
  }


  replay(speed) {
    if (speed === 'stop') {
      this.playReplay = false;
      if (this.replaySubsriber) {
        this.replaySubsriber.unsubscribe();
      }
    } else {
      this.playReplay = true;
      this.replaySubsriber = interval(500)
          .subscribe(() => {
            this.sliderValue = this.sliderValue + 1;
            if (this.sliderValue > this.coords.length) {
              this.replaySubsriber.unsubscribe();
              return;
            }
            this.sliderPath.emit({
              data: this.coords,
              speed: 'fast',
              sliderValue: this.sliderValue,
              type_id: this.device.type_id,
            });


          });
    }

  }

  onInputChange(ev: any) {
    this.playReplay = false;
    if (this.replaySubsriber) {
      this.replaySubsriber.unsubscribe();
    }
    this.sliderValue = ev.target.valueAsNumber;
    this.sliderPath.emit({
      data: this.coords,
      speed: 'fast',
      sliderValue: this.sliderValue,
      type_id: this.device.type_id,
    });
  }
}
