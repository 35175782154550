import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Auth} from '../../../@core/models';
import {Router} from '@angular/router';
import {DataService, ToastService} from '../../../@core/utils';
import {Location} from '@angular/common';

@Component({
	selector: 'ngx-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, AfterViewInit, OnChanges {

	@Input()
	id: string = null;
	auth: Auth = <Auth>{};
	password: string = undefined;
	roles$: Promise<any>;
	loading: boolean = false;
	@Output()
	authId: EventEmitter<string> = new EventEmitter();

	constructor(private http: DataService, private toaster: ToastService,
				private _location: Location, private router: Router) {
	}

	ngOnInit() {

	}

	ngAfterViewInit(): void {
		if (this.id) {
			this.getAuth().then();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('id') && changes.id.currentValue) {
			this.getAuth();
		}
	}

	async getAuth() {
		try {
			this.auth = await this.http.get(this.id, {__exclude: ['user', 'rider', 'merchant']}, 'auth', 'auth');
		} catch (e) {

		}
	}

	async save() {
		this.loading = true;
		if (this.password) {
			this.auth.password = this.password;
		}
		try {
			if (this.id) {
				await this.http.update(this.id, this.auth, {}, 'auth', 'auth');
			} else {
				const res = await this.http.create(this.auth, {__only: 'id'}, 'auth', 'auth');
				this.id = res[0].id;
				this.auth.id = res[0].id;
				this.authId.emit(this.auth.id);
			}
			this.toaster.showToast('Saved auth successful', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error saving auth', 'Error', true, e);
		}
		this.loading = false;
	}

	async cancel() {
		this._location.back();
	}
}
