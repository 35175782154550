<div [nbSpinnerMessage]="'Please wait!!'" [nbSpinnerSize]="'large'" [nbSpinner]="spinner"
	 class="w-100">

	<ng-select (change)="emitSelected($event)"
			   [clearSearchOnAdd]="true"
			   [closeOnSelect]="true"
			   [items]="data$ | async"
			   (open)="loadPeople()"
			   [loading]="dataLoading"
			   (search)="failed.emit($event.term);inputInit($event)"
			   [minTermLength]="1"
			   [multiple]="multiple"
			   (clear)="cleared.emit('all')"
			   [typeahead]="dataInput$"
			   [placeholder]="placeholder || 'Search Here'"
			   appendTo="body"
			   [(ngModel)]="selectedItems"
			   [bindLabel]="text">

		<ng-template let-item="item" ng-option-tmp>
			{{ resultFormatter(item) }}
		</ng-template>

<!--		<ng-template ng-footer-tmp>-->
<!--			<button (click)="selectAll()" nbButton size="small" status="control">Select all</button>-->
<!--		</ng-template>-->

	</ng-select>

</div>
