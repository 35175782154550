import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';

@Component({
	selector: 'ngx-rider-list',
	templateUrl: './rider-list.component.html',
	styleUrls: ['./rider-list.component.scss'],
})
export class RiderListComponent implements OnInit {

	@Input()
	enable: boolean = false;
	@Input()
	serial: string = '1';
	@Input()
	users: any[] = [];
	@Input()
	zoneId: string;
	@Input()
	vehicleId: number;
	@Input()
	isAdmin: boolean;
	@Input()
		// isRider: boolean;
	filters: any;
	infoPopup = 'If unable to add riders, create zone first (step 1)';

	constructor(private http: DataService, private toaster: ToastService, private router: Router) {
	}

	ngOnInit() {
		this.getUser().then();
		this.filters = {__only: ['id', 'name', 'mobile_number'], __zone_id__ne: this.zoneId, __is_active__bool: 'true'};
	}

	async getUser() {
		if (this.zoneId) {
			try {
				const query = {
					__only: ['id', 'name', 'mobile_number'], __zone_id__equal: this.zoneId,
					user: true, __limit: 100,
				};
				const users = await this.http.query(query, 'rider', 'auth');
				if (users.data && users.data.length) {
					this.users = users.data;
				} else {
					this.users = [];
				}
			} catch (e) {
			}
		}

	}

	async addUser(event) {
		if (!this.vehicleId && this.zoneId) {
			try {
				await this.http.update(event.id, {
					zone_id: this.zoneId,
				}, {}, 'rider', 'auth');
				this.users.push(event);
				this.toaster.showToast('Successfully added user.', 'Success', false);
			} catch (e) {
				this.toaster.showToast('Error adding user ', 'Error', true, e);
			}
		} else if (!this.zoneId && this.vehicleId) {
			try {
				await this.http.update(event.id, {vehicle_id: this.vehicleId}, {}, 'rider', 'auth');
				this.users.push(event);
				this.toaster.showToast('Successfully added user.', 'Success', false);
			} catch (e) {
				this.toaster.showToast('Error adding user ', 'Error', true, e);
			}
		} else {
			this.toaster.showToast('Error adding user: Please fill details first. ', 'Error', true);
		}

	}

	async removeUser(id: number, index: number) {
		if (!this.vehicleId && this.zoneId) {
			try {
				await this.http.update(id, {
					zone_id: null,
				}, {}, 'rider', 'auth');
				this.users.splice(index, 1);
				this.toaster.showToast('Successfully removed user.', 'Success', false);
			} catch (e) {
				this.toaster.showToast('Error removing user ', 'Error', true, e);
			}
		} else if (!this.zoneId && this.vehicleId) {
			try {
				await this.http.update(id, {vehicle_id: null}, {}, 'rider', 'auth');
				this.users.splice(index, 1);
				this.toaster.showToast('Successfully removed user.', 'Success', false);
			} catch (e) {
				this.toaster.showToast('Error removing user ', 'Error', true, e);
			}
		} else {
			this.toaster.showToast('Error removing user: Please fill details first. ', 'Error', true);
		}
	}

	editUser(id?: number) {
		this.router.navigate(['/pages/management/base/rider/' + (id ? id : 'new')]);
	}

}
