<div [class.left]="position === 'normal'"
	 [class.right]="position === 'inverse'"
	 class="header-container col-md-4">
	<div class="logo-containter">
		<a (click)="toggleSidebar()" class="navigation" href="#">
			<nb-icon [icon]="'menu'" [options]="{ width: 36, height: 36  }" style="width: 31px;height: 45px;"></nb-icon>
		</a>
		<div (click)="goToHome()" class="logo"></div>
	</div>


</div>

<ng-container *ngIf="user">
	<div class="col-md-4 header-container center-block">
		<img (error)="updateUrl($event)" alt=""
			 [src]="user.company?.icon"
			 class="d-block mx-auto" height="45">
	</div>


	<div class="col-md-4 header-container right">

		<nb-actions
			size="medium">
<!--			<nb-action class="control-item downloads-count">-->
<!--				<nb-icon icon="settings-outline"></nb-icon>-->
<!--				<span class="h6 font-weight-normal number ml-3 mt-2">-->
<!--				v3.1.2-->
<!--			</span>-->
<!--			</nb-action>-->


			<nb-action class="control-item">
				<nb-user [name]="user?.user?.name"
						 [nbContextMenu]="userMenu"
						 [picture]="profileIcon"
						 [title]="user.company?.name" nbContextMenuTag="my-context-menu"></nb-user>
			</nb-action>

		</nb-actions>

		<div class="float-left d-flex justify-content-center align-items-center" style="height: 100%">
			<div class="mx-3 versionShow" data-toggle="tooltip" data-placement="bottom"
			title="Ctrl + Shift + r (To Check for Updates)">
				v{{currentApplicationVersion}}
			</div>
			<div nbTooltip="Notification" style="position: relative">
				<nb-badge [text]="'+' + unSeenNotification" status="danger" position="top right" style="border-radius: 0.3rem"
						  *ngIf="unSeenNotification > 0" [ngStyle]="unSeenNotification > 9 ? {'height': '19px','width': '26px','top':'-5px',
						  'left':'18px'} : {'height': '18px','width': '21px','top':'-5px','right':'-10px'}"></nb-badge>
				<img src="./assets/images/notification-pngrepo-com.png" alt="" height="30" width="30"
					 (click)="toggleNotificationBody=!toggleNotificationBody;unSeenNotification=0"
					 style="cursor: pointer;"/>
			</div>
		</div>

		<div *ngIf="toggleNotificationBody" class="notificationToggle" style="">
			<nb-card style="width: 100%;height: 100%;background: white;border-radius: 10px">
				<nb-card-header style="width: 100%; background: dodgerblue;border-top-left-radius: 10px;
                                        border-top-right-radius: 10px">
					<div class="d-flex justify-content-center" style="color: white;font-size: 18px">Notifications</div>
				</nb-card-header>
				<nb-card-body style="height: 100%;width:100%;overflow: auto">
					<ul class="nav nav-pills justify-content-center">
						<li class="nav-item">
							<div [ngClass]="currentTab==='order' ? 'nav-link active' : 'nav-link'" aria-current="page"
								 (click)="currentTab='order'" style="text-decoration: none;cursor: pointer">Order
							</div>
						</li>
						<li class="nav-item">
							<div [ngClass]="currentTab==='event' ? 'nav-link active' : 'nav-link'" (click)="currentTab='event'"
								 style="text-decoration: none;cursor: pointer" aria-current="page">Event
							</div>
						</li>
					</ul>
					<div *ngFor="let notify of notificationData">
						<div *ngIf="notify.type === currentTab" style="padding: 5px 0 16px 0">
							<div class="border" style="min-height: 5vh;border-radius: 6px;padding:8px">
								<div>
									<div class="mb-2"><Strong>{{underscoreConvert(notify.title) | titlecase}}</Strong></div>
									<p style="font-size: 0.84rem">{{underscoreConvert(notify.body)}}</p>
								</div>
								<div style="text-align: right;color: grey;font-size:12px">
									{{(sameDateCheck(notify.created_on) ? (notify.created_on | date: 'hh:mm a') :
										(notify.created_on | date: 'dd-MM-yyyy'))}}
								</div>
							</div>
						</div>

					</div>
				</nb-card-body>
			</nb-card>
		</div>

		<!--		<nb-actions ngbDropdown size="medium">-->
<!--			<nb-action (click)="loadNext()" class="control-item"-->
<!--					   icon="bell-outline" ngbDropdownToggle>-->
<!--				<div aria-labelledby="dropdownForm1" class="notifications"-->
<!--					 ngbDropdownMenu-->
<!--					 style="width: 250px;height: 400px;overflow-y: scroll;padding: 0;box-shadow: rgb(222, 222, 222) 1px 1px 1px 1px;left:-100%;">-->
<!--					<nb-list-->
<!--					>-->
<!--						<nb-list-item *ngFor="let event of events">-->
<!--							<div class="text-dark">-->

<!--								<nb-user [name]="event?.user?.name"-->
<!--										 [title]="event?.event?.replace('_', ' ').toUpperCase() + ' at ' + (event.updated_on |  date:'dd/MM/yy h:mm:ss a')"-->
<!--										 size="small">-->

<!--								</nb-user>-->


<!--							</div>-->
<!--						</nb-list-item>-->

<!--					</nb-list>-->
<!--				</div>-->
<!--			</nb-action>-->
<!--		</nb-actions>-->


	</div>
</ng-container>
