<div>
    <div class=" p-2" >
        <div class="d-flex justify-content-between">
            <div><strong>Show Path</strong></div>
        </div>

        <div class="my-3">
            <div class="px-2">
                <mbsc-datepicker
                        (onChange)="selectedDateRange($event)"
                        [controls]="['calendar', 'time']"
                        [placeholder]="'Pick Date Range'"
                        [touchUi]="true"
                        inputStyle="outline"
                        select="range"
                >
                </mbsc-datepicker>

            </div>
            <div class="d-flex justify-content-center">
                <div *ngIf="showTrack" class="mx-1 px-2">
                    <button (click)="track()" nbButton nbTooltip="Show Path" shape="rectangle" size="small" status="info">
                        <nb-icon icon="paper-plane-outline"></nb-icon>
                    </button>
                </div>
                <div *ngIf="!showTrack" class="mx-1 px-2">
                    <button (click)="stopSlider()" nbButton nbTooltip="Hide Path" shape="rectangle" size="small"
                            status="danger">
                        <nb-icon icon="paper-plane-outline"></nb-icon>
                    </button>
                </div>
                <div *ngIf="!playReplay" class="px-2 mx-1">
                    <button (click)="replay('fast')" [disabled]="!(!showTrack && coords.length)" nbButton
                            nbTooltip="Play Replay" size="small" status="info">
                        <nb-icon icon="arrow-right"></nb-icon>
                    </button>
                </div>

                <div *ngIf="playReplay" class="px-2 mx-1">
                    <button (click)="replay('stop')" nbButton nbTooltip="Clear Replay" size="small" status="danger">
                        <nb-icon icon="pause-circle"></nb-icon>
                    </button>
                </div>
            </div>

        </div>
{{!showTrack}}
        {{coords.length}}
        <div *ngIf="!showTrack && coords.length" class="slidecontainer py-2">
            <input (input)="onInputChange($event)" [value]="sliderValue" class="slider" id="replayslider" [max]="coords?.length"
                   min="0" step="1" type="range">
        </div>

    </div>
    <div class=" pl-2" *ngIf="showTrack && device.curr_address !== 'Loading...'">
        <strong>Address: </strong>
        <span class="text-muted" colspan="2" style="max-width: 350px">{{ device.curr_address }}</span>
    </div>
</div>
