import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';
import {Category} from '../../../@core/models';
import {Vendor} from "../../../@core/models/vendor";

@Component({
  selector: 'ngx-partner-category-rate-list',
  templateUrl: './partner-category-rate-list.component.html',
  styleUrls: ['./partner-category-rate-list.component.scss']
})
export class PartnerCategoryRateListComponent implements OnInit {

  @Input()
  serial: string = '1';
  @Input()
  partner: Vendor[] = [];
  @Input()
  categoryRateList: string;

  constructor(private http: DataService, private toaster: ToastService, private router: Router) { }

  ngOnInit() {
  }

  async addPartnerPricing(event) {
    try {
      await this.http.create({
        __action: 'add',
        vendor_id: event.id,
        rate_list_id: this.categoryRateList,
      }, {}, 'vendor_rate_list', 'auth');
      this.partner.push(event);
      this.toaster.showToast('Successfully linked category.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error adding category ', 'Error', true, e);
    }
  }

  async removePartnerPricing(id: number, index: number) {
    try {
      await this.http.create({
        __action: 'remove',
        vendor_id: id,
        rate_list_id: this.categoryRateList,
      }, {}, 'vendor_rate_list', 'auth');
      this.partner.splice(index, 1);
      this.toaster.showToast('Successfully removed category.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error removing category ', 'Error', true, e);
    }
  }

  editCategory(id?: number) {
    this.router.navigate(['/pages/management/base/partner/' + (id ? id.toString(10) : 'new')]);
  }

}
