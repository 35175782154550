import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'sortBy',
})
export class SortByPipe implements PipeTransform {

	transform(obj: any[], orderField: string): any {
		obj.sort(function (a, b) {
			if (a[orderField] < b[orderField]) return -1;
			if (a[orderField] > b[orderField]) return 1;
			return 0;
		});
		return obj;
	}

}
