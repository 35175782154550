import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DataService} from '../../../@core/utils';

@Component({
	selector: 'ngx-rider-dropdown',
	templateUrl: './rider-dropdown.component.html',
	styleUrls: ['./rider-dropdown.component.scss'],
})
export class RiderDropdownComponent implements OnInit {
	riders: any[];

	@Output() sendRiderId: EventEmitter<number> = new EventEmitter();

	constructor(private http: DataService) {
	}

	ngOnInit() {
		this.getRiders().then();
	}

	async getRiders() {
		try {
			const riders = await this.http.query({__only: 'id,name', __is_rider__bool: true}, 'user');
			this.riders = riders.data;
		} catch (e) {
		}
	}
}
