import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Pages} from '../../../@core/models/Pages';

@Component({
    selector: 'ngx-new-pagination',
    templateUrl: './new-pagination.component.html',
    styleUrls: ['./new-pagination.component.scss'],
})

export class NewPaginationComponent implements OnInit {
    @Input('totalPages') totalPage: number = 0;
    @Input('page') page = 1;
    @Input('currPage') currPage = 1;
    @Output() next: EventEmitter<Pages> = new EventEmitter<Pages>();
    @Output() previous: EventEmitter<Pages> = new EventEmitter<Pages>();
    @Output() setPages: EventEmitter<Pages> = new EventEmitter<Pages>();

    constructor() {
    }

    ngOnInit(): void {
    }

    async nextPage() {
        this.currPage = (this.currPage < this.totalPage ? (this.currPage + 1) : this.currPage);
        this.page = this.currPage;
        this.next.emit({
          currPage: this.currPage,
          page: this.page,
        });

    }

    async previousPage() {
        this.currPage = (this.currPage > 1) ? (this.currPage - 1) : this.currPage;
        this.page = this.currPage;
        this.previous.emit({
          currPage: this.currPage,
          page: this.page,
        });


    }

    async setPage() {
        this.currPage = (this.currPage > this.totalPage) ? this.totalPage : ((this.currPage <= 0) ? 1 : this.currPage);
        this.page = this.currPage;
      this.setPages.emit({
        currPage: this.currPage,
        page: this.page,
      });

    }
}
