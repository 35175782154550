import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DataService, ToastService} from '../../../@core/utils';
import {NbCalendarRange, NbDialogRef, NbDialogService} from '@nebular/theme';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import {LocalDataSource} from 'ng2-smart-table';
import * as XLSX from 'xlsx';
import {CeleryTaskStatusDialogComponent} from '../celery-task-status-dialog/celery-task-status-dialog.component';

export interface Column {
    name: string;
    sortable?: boolean;
    filter?: boolean;
    displayName: string;
    filterFn?: () => {};
    sortFn?: () => {};
    displayFn?: (row: any, columnName: any) => {};
}

@Component({
    selector: 'ngx-data-table', templateUrl: './data-table.component.html', styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() columns: Column[] = [];
    @Input() auto: boolean = true;
    @Input() dialogInputColumn: any[] = [];
    @Input() dialogCheckboxColumn: any[] = [];
    @Input() dialogDropdownColumn: any[] = [];
    @Input() filters: any[] = [];
    @Input() only: string[] = undefined;
    @Input() include: string[] = undefined;
    @Input() path: string;
    @Input() downloadPath: string;
    @Input() baseUrl: string = 'auth';
    @Input() editPath: string;
    @Input() addNew = true;
    @Input() searchIcon = true;
    @Input() footer = true;
    @Input() inputQuery: any = {};
    @Input() infoIcon: boolean = false;
    @Input() editIcon: boolean;
    @Input() folderIcon: boolean;
    @Input() disableIcon: boolean;
    @Input() deleteIcon: boolean;
    @Input() deleteColumn: string = null;
    @Input() disableColumn: string = null;
    @Input() refresh: boolean = false;
    @Input() searchField: string = null;
    @Input() multipleZoneEvent: any[] = [];
    @Input() searchFields: any[] = [];
    @Input() searchTerm: string = undefined;
    @Input() riderSearch: boolean = false;
    @Input() dateFilter: boolean = false;
    @Input() singleDay: boolean = false;
    @Input() canDownload: boolean = true;
    @Input() orderDownload: boolean = false;
    @Input() isInvoice: boolean = false;
    @Input() monthFilter: boolean = false;
    @Input() excelUpload: boolean = false;
    @Input() timeFilter: boolean = false;
    @Input() multipleZone: boolean = false;
    @Input() isManage: boolean = false;
    riderId: string;
    @Output() sendQuery: EventEmitter<any> = new EventEmitter<any>();

    dateRange: NbCalendarRange<Date> = {start: new Date, end: new Date()};
    dateFilterRange;
    page: number = 1;
    totalPages: any;
    currPage: number;
    @Input() limit = 10;
    data: any[] = [];
    query: any = {};
    startDate: string;
    endDate: string;
    startTime: any;
    endTime: any;
    startDateFilter: string;
    endDateFilter: string;
    fromDate: string;
    toDate: string;
    date: Date;
    loading: boolean = true;
    tableDataSource: LocalDataSource = new LocalDataSource();
    file: File;
    tableSettings = {
        actions: {
            add: false, edit: false, delete: false, search: false,
        }, pager: {
            display: true, perPage: 10,
        }, columns: {
            name: {title: 'Name', filter: false},
            password: {title: 'Password', filter: false},
            mobile_number: {title: 'Mobile Number', filter: false},
            email: {title: 'Email', filter: false},
            zone: {title: 'Zone', filter: false},
            employee_id: {title: 'Employee ID', filter: false},
        },
    };

    @Output() view: EventEmitter<any> = new EventEmitter<any>();
    @Output() download_pdf: EventEmitter<any> = new EventEmitter<any>();
    @Output() upload_excel: EventEmitter<any> = new EventEmitter<any>();
    selectedMonth: Date;
    dialogRef: NbDialogRef<any>;
    closePopup = true;
    selectedDateTime = '';
    min = new Date();
    max: number;
    dateControls = ['calender'];
    zoneIds: any = [];

    constructor(private http: DataService, private router: Router, private toaster: ToastService, public nbDialogService: NbDialogService, private activatedRoute: ActivatedRoute) {
        this.totalPages = 1;
        this.currPage = 1;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.orderDownload) {
            this.startDate = moment(new Date().setHours(0, 0)).toJSON();
            this.endDate = moment(new Date().setHours(0, 0)).add(1, 'day').toJSON();
            this.selectedDateTime = '' + moment(this.startDate).format('DD/MM/YYYY') + ' - ' + moment(this.endDate).format('DD/MM/YYYY');
        }
        if ((changes.hasOwnProperty('inputQuery') && !changes.inputQuery.firstChange) || (changes.hasOwnProperty('refresh') && changes.refresh.currentValue === true)) {
            this.loadData().then();
        }
    }

    ngOnInit() {
        this.max = this.min.setMonth(this.min.getMonth() - 12);
        this.startDate = moment(new Date().setHours(0, 0, 0)).toJSON();
        this.endDate = moment(new Date().setHours(23, 59, 59)).toJSON();
    }

    ngAfterViewInit() {
        const params = window.sessionStorage.getItem('query');
        if (params) {
            const data = JSON.parse(params);
            try {
                if (data.hasOwnProperty('path') && JSON.parse(data['path']) === this.path) {
                    return this.setFilterValues(data);
                } else {
                    window.sessionStorage.removeItem('query');
                }
            } catch (e) {
                window.sessionStorage.removeItem('query');
            }
        }
        this.loadData().then();
    }

    setFilterValues(params: Params) {
        for (const paramsKey in params) {
            if (params.hasOwnProperty(paramsKey)) {
                try {
                    this[paramsKey] = JSON.parse(params[paramsKey]);
                } catch (e) {
                    console.error(e);
                }

            }
        }
        this.currPage = this.page;
        this.filters.forEach((filter) => {
            this.query[filter.value] = filter.checked;
        });
        if (this.dateFilter) {
            this.changeRange(this.dateRange);
        } else {
            this.loadData().then();
        }
    }

    async nextPage() {
        this.currPage = (this.currPage < this.totalPages) ? (this.currPage + 1) : this.currPage;
        this.page = this.currPage;
        await this.loadData();
    }

    async previousPage() {
        this.currPage = (this.currPage > 1) ? (this.currPage - 1) : this.currPage;
        this.page = this.currPage;
        await this.loadData();
    }

    async setPage() {
        this.currPage = (this.currPage > this.totalPages) ? this.totalPages : ((this.currPage <= 0) ? 1 : this.currPage);
        this.page = this.currPage;
        await this.loadData();
    }

    async search(clear?: boolean) {
        if (clear) {
            this.searchFields.forEach(s => {
                if (s.hasOwnProperty('term')) {
                    s.term = undefined;
                    this.startDateFilter = this.endDateFilter = undefined;
                    this.dateFilterRange = null;
                }
            });
        } else {
            this.closePopup = true;
        }
        this.page = 1;
        this.loadData().then();
    }

    async loadData() {
        try {
            const data = await this.getData();
            this.data = data.data;
            if (this.path === 'order_report') {
                for (const order of this.data) {
                    order.start_odometer_reading = order.start_odometer ? order.start_odometer.reading : 0;
                    order.end_odometer_reading = order.end_odometer ? order.end_odometer.reading : 0;
                }
            }
            this.totalPages = Math.ceil(data.total / this.limit);
        } catch (e) {
            console.log(e);
            this.data = [];
        }
        this.loading = false;
    }


    getData(): Promise<any> {
        if (this.monthFilter && (!this.startDate || !this.endDate)) {
            const newDate = new Date();
            this.startDate = moment(newDate).startOf('month').format('YYYY-MM-DD');
            this.endDate = moment(newDate).endOf('month').format('YYYY-MM-DD');
            this.selectedMonth = moment(newDate).toDate();
        }
        if (!this.auto && (!this.startDate || !this.endDate)) {
            return;
        }
        this.query.__zone_id__in = this.zoneIds;
        console.log('this.zoneIds', this.zoneIds);
        console.log('this.query.__zone_id_in', this.query.__zone_id__in);
        this.query.__page = this.page;
        this.query.__only = this.only;
        this.query.__include = this.include;
        this.query.__limit = this.limit;
        this.query.__rider_id__equal = this.riderId;

        if (this.searchField) {
            this.query[this.searchField] = this.searchTerm;
        }
        if (this.searchFields) {
            this.searchFields.forEach(s => {

                if (s.hasOwnProperty('term')) {
                    if ((s.type === 'date-range-start' || s.type === 'date-range-end') && (this.startDateFilter && this.endDateFilter)) {
                        this.query[s.value] = s.type === 'date-range-start' ? this.startDateFilter : this.endDateFilter;
                    } else {
                        this.query[s.value] = s.term;
                    }
                }
            });
        }

        if (this.isInvoice) {
            this.query.__date__date_gte = this.fromDate;
            this.query.__date__date_lte = this.toDate;
        } else if (this.monthFilter) {
            this.query.__start__equal = this.startDate;
            this.query.__end__equal = this.endDate;
        } else {
            if (!this.isManage) {
                this.query.__created_on__date_gte = this.startDate;
                this.query.__created_on__date_lte = this.endDate;
            }

        }

        for (const i in this.inputQuery) {
            if (this.inputQuery.hasOwnProperty(i)) {
                this.query[i] = this.inputQuery[i];
            }
        }
        if (this.query['__export__']) {
            delete this.query['__export__'];
        }
        const queryObject = this.query;
        this.sendQuery.emit(queryObject);

        return this.http.query(this.query, this.path, this.baseUrl);
    }

    applyFilter(filter: any, value: boolean) {
        filter.checked = value ? true : undefined;
        this.query[filter.value] = filter.checked;
        this.page = 1;
        this.loadData().then();
    }

    async download() {
        const query = Object.assign({}, this.query);
        query.__export__ = true;
        return await this.http.downloadFile(query, this.path, this.baseUrl);
    }

    async saveAs() {
        try {
            if (this.downloadPath) {
                const query = Object.assign({}, this.query);
                query.__export__ = true;
                const task_status = await this.http.query(query, this.downloadPath, this.baseUrl);
                this.nbDialogService.open(CeleryTaskStatusDialogComponent, {
                    context: {
                        taskId: task_status['task_id'], title: 'Generating Report...',
                    },
                });

            } else {
                const data = await this.download();
                const blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
                if (this.query.__report_name) {
                    saveAs(blob, this.query.__report_name + '_' + new Date().toISOString().slice(0, 19).replace(/T/, '_') + '.csv');
                } else {
                    saveAs(blob, new Date().toISOString().slice(0, 19).replace(/T/, '_') + '.csv');
                }
            }
        } catch (e) {
            return;
        }
    }

    async edit(id?: number) {
        window.sessionStorage.setItem('query', JSON.stringify({
            page: this.page,
            only: this.only,
            path: JSON.stringify(this.path),
            include: this.include,
            limit: this.limit,
            startDate: JSON.stringify(this.startDate),
            endDate: JSON.stringify(this.endDate),
            inputQuery: JSON.stringify(this.inputQuery),
            searchFields: JSON.stringify(this.searchFields),
            filters: JSON.stringify(this.filters),
        }));
        this.router.navigate([this.editPath + (id ? id.toString(10) : 'new')], {
            relativeTo: this.activatedRoute, queryParamsHandling: 'merge',
        }).then();

    }

    disable(row: any, column: string, dialogBox) {
        this.nbDialogService.open(dialogBox, {
            context: {
                toggle: !row[column],
            },
        }).onClose.subscribe(async res => {
            if (res) {
                const obj = {};
                obj[column] = !row[column];
                await this.http.update(row.id, obj, {}, this.path, this.baseUrl);
                row[column] = !row[column];
            }
        });
    }

    async delete(row: any) {
        const index = this.data.findIndex(x => x.id === row.id);
        this.data.splice(index, 1);
        await this.http.delete(row.id, {}, this.path, this.baseUrl);
    }

    setRiderId(ev) {
        this.riderId = ev;
        this.loadData().then();

    }

    changeRange(event: any) {
        this.isManage = false;
        if (event && event.value[0] && event.value[1]) {
            this.startDate = event.value[0].toJSON();
            this.endDate = event.value[1].toJSON();
            this.page = 1;
            this.loadData().then();
        } else {
            this.startDate = undefined;
            this.endDate = undefined;
        }
    }

    dateChange(ev: Date) {
        if (ev) {
            this.startDate = ev.toJSON();
            const endDate = ev;
            endDate.setDate(ev.getDate() + 1);
            this.endDate = endDate.toJSON();

            this.currPage = 1;
            this.page = 1;
            this.loadData();
        }
    }

    selectMonth(event) {
        this.startDate = moment(event.value[0]).startOf('month').format('YYYY-MM-DD');
        this.endDate = moment(event.value[1]).endOf('month').format('YYYY-MM-DD');
        this.selectedMonth = moment(event).toDate();
        this.currPage = 1;
        this.page = 1;
        this.loadData();
    }

    changeInvoiceRange(event: any) {
        if (event && event.value[0] && event.value[1]) {
            this.fromDate = event.value[0].toJSON();
            this.toDate = event.value[1].toJSON();
            this.page = 1;
            this.loadData().then();
        } else {
            this.fromDate = undefined;
            this.toDate = undefined;
        }
    }

    invoiceDateChange(ev: any) {
        if (ev) {
            this.fromDate = ev.value[0].toJSON();
            const toDate = ev.value[1];
            toDate.setDate(ev.getDate() + 1);
            this.toDate = toDate.toJSON();

            this.currPage = 1;
            this.page = 1;
            this.loadData();
        }
    }

    async fileUpload(e): Promise<any> {

        this.tableDataSource.reset();
        const file: File = e.files[0];
        const reader = new FileReader();
        const tableData: Array<any> = await this.tableDataSource.getAll();
        reader.onloadend = () => {
            const data = reader.result;
            const workbook = XLSX.read(data, {type: 'binary', cellDates: true});

            const worksheet = workbook.Sheets.Sheet1;
            const payout: Array<any> = XLSX.utils.sheet_to_json(worksheet, {raw: false});
            this.tableDataSource.load(payout.concat(tableData));
            this.file = file;

        };
        reader.readAsBinaryString(file);

    }

    async uploadFile() {
        const tableData: Array<any> = await this.tableDataSource.getAll();
        if (tableData.length > 0) {
            this.upload_excel.emit(tableData);
            this.dialogRef.close();
        } else {
            this.toaster.showToast('PLease choose a file!', 'Error', true);
        }
    }

    closeDialog() {
        this.tableDataSource = new LocalDataSource();
        this.dialogRef.close();
    }

    filterDateRange(event: any) {
        if (event && event.value[0] && event.value[1]) {
            this.startDateFilter = event.value[0].toJSON();
            this.endDateFilter = event.value[1].toJSON();
        } else {
            this.startDateFilter = undefined;
            this.endDateFilter = undefined;
        }
    }

    async orderReportDownload() {
        const query = Object.assign({}, this.query);
        query.__order_by = '-created_on';
        query.__created_on__date_gte = this.startDate;
        query.__created_on__date_lte = this.endDate;
        query.__export__ = true;
        query.__report_name = 'order_report';
        this.startDate = this.startDate ? this.startDate : moment(new Date()).set('hours', 5).set('minutes', 30).toJSON();
        this.endDate = this.endDate ? this.endDate : moment(this.startDate).add(23, 'hours').add(59, 'minutes').toJSON();
        const response = await this.http.query(query, 'order_report_async', 'report');
        this.nbDialogService.open(CeleryTaskStatusDialogComponent, {
            context: {
                taskId: response.task_id, title: 'Generating Report...',
            },
        });
    }

    setDateTime(event) {
        if (event && event.value[0] && event.value[1]) {
            this.startDate = event.value[0].toJSON();
            this.endDate = event.value[1].toJSON();
            this.page = 1;
            this.loadData().then();
        }
    }

    multiZoneFilter(zone) {
        const zoneId = zone.id;
        this.zoneIds = [];
        zone.forEach(row => {
            this.zoneIds.push(row.id);
        });
        this.query.__zone_id__in = this.zoneIds;
        this.loadData().then();
        if (zone.length === 0) {
        }
    }
}
