<nb-card>
	<nb-card-header>{{serial}}. Stores</nb-card-header>
	<nb-card-body class="p-4">
		<ngx-type-ahead (send)="addOutlet($event)"
						[basePath]="'auth'"
						[disabled]="!id" [filters]="filters" [model]="{}"
						[placeholder]="'Search store here'" [required]="true"
						[url]="'outlet'">

		</ngx-type-ahead>

		<div class="mt-5">
			<nb-list style="max-height: 400px; overflow-y: auto">
				<nb-list-item *ngFor="let outlet of outlets; let i = index">
					<div class="d-flex justify-content-between align-items-center">
						<nb-user (click)="editOutlet(outlet.id)" [name]="outlet.name" [title]="outlet?.brand?.name"
								 class="cursor">

						</nb-user>
						<nb-icon (click)="removeOutlet(outlet.id, i)" class="cursor" icon="close"
								 status="danger"></nb-icon>
					</div>

				</nb-list-item>
			</nb-list>
		</div>

	</nb-card-body>

	<nb-card-footer class="d-flex justify-content-between">
		<div></div>

		<div>
			<button (click)="prevPage()" [disabled]="page < 2" class="mr-3" nbButton shape="rectangle" size="small"
					status="secondary">
				<nb-icon icon="arrow-ios-back-outline"></nb-icon>
			</button>
			<button (click)="prevPage()" nbButton shape="rectangle" size="small" status="secondary">
				<nb-icon icon="arrow-ios-forward-outline"></nb-icon>
			</button>
		</div>
		<div>
			<button (click)="editOutlet()" nbButton shape="rectangle" status="primary">Add New</button>
		</div>

	</nb-card-footer>

</nb-card>
