import {Injectable} from '@angular/core';
import {HttpService, IRestConfig} from '../../config/http.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthService extends HttpService<any> {

	constructor(http: HttpClient, config: IRestConfig) {
		super(http, {
			path: '',
			baseUrl: environment.auth,
		});
	}

}
