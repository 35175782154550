
<ng-container *ngIf="cardType === 'first'">
    <nb-card [ngClass]="'position-relative border-0 m-' + margin +' '+ cardSize"
    [ngStyle]="{'text-align': textAlign}"
    [size]="cardSize">
    <div class="position-absolute" style="left: 10px;top: 10px;">
        <nb-icon [icon]="iconName" [options]="{ fill: iconFill }"></nb-icon>
    </div>
    <nb-card-header class="{{headerBg}} border-0" style="font-size: 1.5rem" *ngIf="countryCode">{{body !== 'TOTAL ORDERS' && body !== 'CASH COLLECTED ORDERS' && body !== 'DEPOSITED ORDERS' ? (countryCode['country'] ?  (header | currency : countryCode['country'].currencyCode) : (header | currency : 'INR')) : header}}</nb-card-header>
    <nb-card-body class="pt-0 d-flex flex-column justify-content-between">

        <div [ngClass]="'m-'+bodyMargin" style="font-weight: bold">{{body}}</div>
    </nb-card-body>
    
</nb-card>
</ng-container>

<ng-container *ngIf="cardType === 'second'">
    <nb-card [ngClass]="'border-0 m-' + margin +' '+ cardSize"
    [ngStyle]="{'text-align': textAlign}"
    [size]="cardSize">
    <nb-card-header class="{{headerBg}} border-0 px-0 font-weight-bold">{{header}}</nb-card-header>
    <nb-card-body class="p-0 d-flex flex-column justify-content-between">
        <div [ngClass]="'d-flex justify-content-center p-2 m-'+bodyMargin"
        [ngStyle]="{'font-weight': 'bold','background': bodyBg, 'color': bodyColor}">
        <div class="rounded-circle bg-white p-1 ">
            <nb-icon [icon]="iconName" [options]="{ fill: iconFill}" style="font-size: 2rem"></nb-icon>
        </div>
        <div class="align-self-center ml-2" style="font-size: 32px ;">{{body}}</div>
    </div>
		</nb-card-body>
	</nb-card>
</ng-container>

<ng-container *ngIf="cardType === 'critical_dashboard'">
	<nb-card>
		<div class="d-flex justify-content-between" style="padding:5px 5px 0 5px;">
			<p style="color:#6ED2DE;">{{store_name}}</p>
            <div class="icons">
                <nb-icon *ngIf="!(statusCode > 400 || statusCode < 0) "
                         [icon]="'edit-2-outline'" [nbTooltip]="'Edit'" style="cursor: pointer"
                         (click)="orderActionEmitter.emit('edit')">
                </nb-icon>&nbsp;
                <nb-icon *ngIf="statusCode > 50 && statusCode < 600"
                         [icon]="'corner-up-right-outline'" [nbTooltip]="'Move to next status'" style="cursor: pointer"
                         (click)="orderActionEmitter.emit('next')">
                </nb-icon>
            </div>
		</div>
        <div class="d-flex justify-content-between" style="padding:5px 5px 0 5px;">
            <p>ORDER NO <span style="color:#6ED2DE;">#{{order_no }}</span></p>
        </div>
<!--        <div class="d-flex justify-content-between" style="padding:5px 5px 0 5px;">-->
<!--			<p>EXTERNAL <span style="color:#6ED2DE;">#{{external_id}}</span></p>-->
<!--		</div>-->

		<div class="d-flex justify-content-between align-items-center" style="padding:0 5px 0 5px;">
			<p> &nbsp; {{orders_total}}</p>
			<p style="background: #F1F5F8; border: 4px solid #F1F5F8; border-radius: 5px;">
				<i class="eva-icon-container" style="vertical-align: middle;">
					<nb-icon [icon]="'clock-outline'"></nb-icon>
				</i>
				{{order_status | date : 'short'}}
			</p>
		</div>
	</nb-card>

</ng-container>




