<div *ngIf="totalPage > 1" class="p-2 d-flex justify-content-between align-items-end">
    <div>&nbsp;</div>
    <div [ngClass]="page === 1? 'disabled':''" class="icon">
        <i (click)="previousPage()" class="eva-icon-container previous-page">
            <nb-icon icon="arrow-left-outline"></nb-icon>
        </i>
    </div>
    <div>
        PAGE
        <input [(ngModel)]="currPage" [max]="totalPage" [value]="page" class="ml-3 mr-1" min="1"
               nbInput type="number">
        (max: {{totalPage}})
        <button (click)="setPage()" [shape]="'rectangle'" class="ml-3" nbButton size="small">GO</button>
    </div>
    <div [ngClass]="page === totalPage? 'disabled':''" class="icon">
        <i (click)="nextPage()" class="eva-icon-container next-page cursor-pointer">
            <nb-icon icon="arrow-right-outline"></nb-icon>
        </i>
    </div>
    <div>&nbsp;
    </div>
</div>
