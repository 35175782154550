import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';
import {Zone} from '../../../@core/models';


@Component({
  selector: 'ngx-vehicle-zone-list',
  templateUrl: './vehicle-zone-list.component.html',
  styleUrls: ['./vehicle-zone-list.component.scss'],
})
export class VehicleZoneListComponent implements OnInit, OnChanges {
  @Input()
  serial: string = '1';
  @Input()
  vehicleId: number;
  @Input()
  enable: boolean = false;

  zones: Zone[] = [];
  constructor(private toaster: ToastService, private http: DataService, private router: Router) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('vehicleId')) {
      if (this.vehicleId) {
        this.getZones().then();
      }
    }
  }

  async getZones() {
    try {
      this.zones = (await this.http.query({__vehicle_id__equal: this.vehicleId}, 'zone', 'auth')).data;
    } catch (e) {
      // console.log(e);
    }
  }

  async addZone(event) {
    try {
      await this.http.create({__action: 'add', zone_id: event.id, vehicle_id: this.vehicleId}, {}, 'vehicle_zone', 'auth');
      this.zones.push(event);
      this.toaster.showToast('Successfully create zone.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error removing zone ', 'Error', true, e);
    }
  }

  async removeZone(id: string, index: number) {
    try {
      await this.http.create({__action: 'remove', zone_id: id, vehicle_id: this.vehicleId}, {}, 'vehicle_zone', 'auth');
      this.zones.splice(index, 1);
      this.toaster.showToast('Successfully removed zone.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error removing zone ', 'Error', true, e);
    }
  }

  editZone(id?: string) {
    this.router.navigate(['/pages/management/base/zone/' + (id ? id : 'new')]);
  }

}
