<label>
    <input style="height: 34px;"  nbPopover [nbPopover]="menu" #InputElement readonly [(ngModel)]="selectedDate">
</label>
<ng-template  #menu let-data let-ref="menu" style="box-shadow: 5px 5px darkgrey">
    <ng-container>
            <table>
                <tr>
                    <td>
                        <div style="width: 120px; padding: 10px; justify-content: center" (click)="$event.stopPropagation()">
                            <button (click)="emitToday()" *ngIf="todayAllowed" class="btn btn-primary ml-3 ng-star-inserted">Today</button> <br  *ngIf="todayAllowed" >
                            <button (click)="emitYesterday()" class="btn btn-primary ml-3 ng-star-inserted">Yesterday</button> <br>
                            <button (click)="emitLast7Days()" class="btn btn-primary ml-3 ng-star-inserted">Last 7 Days</button> <br>
                            <!--                    <button (click)="emitLast30Days()">Last 30 Days</button> <br>-->
                            <!--                    <button (click)="emitThisMonth()">This Month</button> <br>-->
                            <!--                    <button (click)="emitLastMonth()">Last Month</button>-->
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="padding: 10px; width: 200px">
                        <div style="justify-content: center">
                            <button (click)="emitCustomRange()" class="btn btn-primary ml-3 ng-star-inserted">Custom Range</button> <br>
                        </div>
                    </td>
                </tr>
            </table>
    </ng-container>
</ng-template>