<nb-card>
    <nb-card-header>{{ titlePrefix }} Device Details</nb-card-header>
    <nb-card-body class="p-4">
        <table class="table table-striped w-100" *ngIf="deviceDetails?.device_type">
            <tbody>
            <tr>
                <td>Version</td>
                <td>{{ deviceDetails.app_version }}</td>
            </tr>
            <tr>
                <td>Manufacturer</td>
                <td>{{ deviceDetails.manufacturer }}</td>
            </tr>
            <tr>
                <td>Model</td>
                <td>{{ deviceDetails.model }}</td>
            </tr>
            <tr *ngIf="deviceDetails.androidVersion">
                <td>Android</td>
                <td>{{ deviceDetails.androidVersion }}</td>
            </tr>
            <tr *ngIf="deviceDetails.iOSVersion">
                <td>iOS</td>
                <td>{{ deviceDetails.iOSVersion }}</td>
            </tr>
            <tr>
                <td>SDK</td>
                <td>{{ deviceDetails.sdk }}</td>
            </tr>
            </tbody>
        </table>
    </nb-card-body>
</nb-card>