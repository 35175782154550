import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.scss'],
})
export class DeviceDetailsComponent implements OnInit {

  @Input()
  deviceDetails: any;

  @Input()
  titlePrefix: any = '';

  constructor() { }

  ngOnInit() {
  }

}
