<nb-card>
	<nb-card-body>
		<div class="table-wrapper-scroll-y my-custom-scrollbar">
			<table class="table table-borderless bg-white data-table">
				<thead class="">
				<tr class="font-weight-bold">
					<th *ngFor="let column of columns">{{column.displayName}}</th>
				</tr>
				</thead>
				<tbody class="fixed-height">
				<ng-container *ngFor="let row of data">
					<tr>
						<ng-container *ngFor="let column of columns">
							<td>{{ column.displayFn ? column.displayFn(row, column) : row[column.name] }}</td>
						</ng-container>
					</tr>
				</ng-container>

				</tbody>
			</table>
		</div>
	</nb-card-body>
</nb-card>

