<div class="container-fluid m-0 p-0 row d-flex justify-content-between">
	<div class="col-md-5 col-sm-12" style="background: #fff; width: 100vw;">

		<ngb-carousel>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random first slide" class="img-fluid"
					src="../../../assets/images/Artboard 27@4x.jpg">
				</div>
				<!--      <div class="carousel-caption">-->
				<!--          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--             blanditiis quaerat porro ut,-->
				<!--             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--        </div>-->
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random second slide" class="img-fluid"
					src="../../../assets/images/Artboard 28@4x.jpg">		
				</div>
				<!--        <div class="carousel-caption">-->
				<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--           blanditiis quaerat porro ut,-->
				<!--           esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--      </div>-->
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random third slide" class="img-fluid"
					src="../../../assets/images/Artboard 29@4x.jpg">
				</div>
				<!--      <div class="carousel-caption">-->
				<!--          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--             blanditiis quaerat porro ut,-->
				<!--             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--        </div>-->
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random third slide" class="img-fluid"
					src="../../../assets/images/Artboard 30@4x.jpg">
				</div>
				<!--      <div class="carousel-caption">-->
				<!--          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--             blanditiis quaerat porro ut,-->
				<!--             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--        </div>-->
			</ng-template>
			<!-- <ol class="carousel-indicators">
			  <li id="ngb-slide-0" class=""></li>
			  <li id="ngb-slide-1" class=""></li>
			  <li id="ngb-slide-2" class="active"></li>
			</ol> -->
		</ngb-carousel>

	</div>


	<div class="col-md-7 col-sm-12" style="background: #F0F5F8;">
		<div class="d-flex justify-content-center mx-auto" style="height: 40px; margin-top: 100px;">
			<img alt="Roadcast Logo" height="50px" [src]="constantsService.logoURL" (error)="updateLogoURL($event)">
		</div>
		<form #form="ngForm" (ngSubmit)="register()" aria-labelledby="title"
			  style="display: flex;justify-content: center;align-items: center;margin-top: 50px;">
			<div class="col-md-4 col-sm-12">

				<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
					<p class="alert-title"><b>Oh snap!</b></p>
					<ul class="alert-message-list" style="list-style-type: none; padding-left: 0px;">
						<li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
					</ul>
				</nb-alert>

				<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
					<p class="alert-title"><b>Hooray!</b></p>
					<ul class="alert-message-list" style="list-style-type: none; padding-left: 0px;">
						<li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
					</ul>
				</nb-alert>

				<div class="form-group">
					<label for="input-name">Full name:</label>
					<input #fullName="ngModel"
						   [(ngModel)]="user.fullName"
						   [attr.aria-invalid]="fullName.invalid && fullName.touched ? true : null"
						   [maxlength]="getConfigValue('forms.validation.fullName.maxLength')"
						   [minlength]="getConfigValue('forms.validation.fullName.minLength')"
						   [required]="getConfigValue('forms.validation.fullName.required')"
						   [status]="fullName.dirty ? (fullName.invalid  ? 'danger' : 'success') : ''"
						   autofocus
						   fieldSize="medium"
						   fullWidth
						   id="input-name"
						   name="fullName"
						   nbInput
						   placeholder="Full name"
						   shape="semi-round"
						   size="small">
					<ng-container *ngIf="fullName.invalid && fullName.touched">
						<p *ngIf="fullName.errors?.required" class="caption status-danger">
							Full name is required!
						</p>
						<p *ngIf="fullName.errors?.minlength || fullName.errors?.maxlength"
						   class="caption status-danger">
							Full name should contains
							from {{getConfigValue('forms.validation.fullName.minLength')}}
							to {{getConfigValue('forms.validation.fullName.maxLength')}}
							characters
						</p>
					</ng-container>
				</div>

				<div class="form-group">
					<label for="input-email">Email address:</label>
					<input #email="ngModel"
						   [(ngModel)]="user.email" [attr.aria-invalid]="email.invalid && email.touched ? true : null"
						   [required]="getConfigValue('forms.validation.email.required')"
						   [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
						   fieldSize="medium"
						   fullWidth
						   id="input-email"
						   name="email"
						   nbInput
						   pattern=".+@.+..+"
						   placeholder="Email address"
						   shape="semi-round"
						   size="small">
					<ng-container *ngIf="email.invalid && email.touched">
						<p *ngIf="email.errors?.required" class="caption status-danger">
							Email is required!
						</p>
						<p *ngIf="email.errors?.pattern" class="caption status-danger">
							Email should be the real one!
						</p>
					</ng-container>
				</div>

				<div class="form-group">
					<label for="input-password">Password:</label>
					<input #password="ngModel"
						   [(ngModel)]="user.password"
						   [attr.aria-invalid]="password.invalid && password.touched ? true : null"
						   [maxlength]="getConfigValue('forms.validation.password.maxLength')"
						   [minlength]="getConfigValue('forms.validation.password.minLength')"
						   [required]="getConfigValue('forms.validation.password.required')"
						   [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
						   fieldSize="medium"
						   fullWidth
						   id="input-password"
						   name="password"
						   nbInput
						   placeholder="Password"
						   shape="semi-round"
						   size="small"
						   type="password">
					<ng-container *ngIf="password.invalid && password.touched">
						<p *ngIf="password.errors?.required" class="caption status-danger">
							Password is required!
						</p>
						<p *ngIf="password.errors?.minlength || password.errors?.maxlength"
						   class="caption status-danger">
							Password should contain
							from {{ getConfigValue('forms.validation.password.minLength') }}
							to {{ getConfigValue('forms.validation.password.maxLength') }}
							characters
						</p>
					</ng-container>
				</div>

				<div class="form-group">
					<label for="input-re-password">Repeat password:</label>
					<input #rePass="ngModel"
						   [(ngModel)]="user.confirmPassword"
						   [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null"
						   [required]="getConfigValue('forms.validation.password.required')"
						   [status]="rePass.dirty ? (rePass.invalid || password.value != rePass.value  ? 'danger' : 'success') : ''"
						   fieldSize="medium"
						   fullWidth
						   id="input-re-password"
						   name="rePass"
						   nbInput
						   placeholder="Confirm Password"
						   shape="semi-round"
						   size="small"
						   type="password">
					<ng-container *ngIf="rePass.invalid && rePass.touched">
						<p *ngIf="rePass.errors?.required" class="caption status-danger">
							Password confirmation is required!
						</p>
						<p *ngIf="password.value != rePass.value && !rePass.errors?.required"
						   class="caption status-danger">
							Password does not match the confirm password.
						</p>
					</ng-container>
				</div>

				<div *ngIf="getConfigValue('forms.register.terms')" class="form-group">
					<nb-checkbox [(ngModel)]="user.terms" [required]="getConfigValue('forms.register.terms')"
								 name="terms">
						Agree to <a href="https://www.roadcast.in/terms-of-use" target="_blank"><strong>Terms &
						Conditions</strong></a>
					</nb-checkbox>
				</div>

				<button [class.btn-pulse]="submitted"
						[disabled]="submitted || !form.valid"
						fullWidth
						nbButton
						shape="semi-round"
						size="small"
						status="primary">
					Register
				</button>
				<br> <br>
				<section aria-label="Sign in or sign up" class="sign-in-or-up" style="text-align: center;">
					<p><a class="text-link font-weight-bold" routerLink="../login">Back to Log In</a></p>
				</section>
			</div>
		</form>


	</div>
</div>
