import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';
import {Zone} from '../../../@core/models';


@Component({
  selector: 'ngx-partner-zone-list',
  templateUrl: './partner-zone-list.component.html',
  styleUrls: ['./partner-zone-list.component.scss']
})
export class PartnerZoneListComponent implements OnInit {

  @Input()
  serial: string = '1';
  @Input()
  partnerId: number;

  zones: Zone[] = [];

  constructor(private http: DataService, private toaster: ToastService, private router: Router) { }

  ngOnInit() {
    if (this.partnerId) {
      this.getZones().then();
    }
  }

  async getZones() {
    try {
      this.zones = (await this.http.query({__vendor_id__equal: this.partnerId}, 'zone', 'auth')).data;
    } catch (e) {
      console.log(e);
    }
  }

  async addZone(event) {
    try {
      await this.http.create({__action: 'add', zone_id: event.id, vendor_id: this.partnerId}, {}, 'vendor_zone', 'auth');
      this.zones.push(event);
      this.toaster.showToast('Successfully create zone.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error removing zone ', 'Error', true, e);
    }
  }

  async removeZone(id: string, index: number) {
    try {
      await this.http.create({__action: 'remove', zone_id: id, vendor_id: this.partnerId}, {}, 'vendor_zone', 'auth');
      this.zones.splice(index, 1);
      this.toaster.showToast('Successfully removed zone.', 'Success', false);
    } catch (e) {
      this.toaster.showToast('Error removing zone ', 'Error', true, e);
    }
  }

  editZone(id?: string) {
    this.router.navigate(['/pages/management/base/zone/' + (id ? id : 'new')]);
  }



}
