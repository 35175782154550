<div class="container-fluid m-0 p-0 row d-flex justify-content-between">
	<div class="col-md-5 col-sm-12" style="background: #fff; width: 100vw;">
		<ngb-carousel>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random first slide" class="img-fluid"
					src="../../../assets/images/Artboard 27@4x.jpg">
				</div>
				<!--      <div class="carousel-caption">-->
				<!--          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--             blanditiis quaerat porro ut,-->
				<!--             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--        </div>-->
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random second slide" class="img-fluid"
						 src="../../../assets/images/Artboard 28@4x.jpg">
				</div>
				<!--        <div class="carousel-caption">-->
				<!--        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--           blanditiis quaerat porro ut,-->
				<!--           esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--      </div>-->
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random third slide" class="img-fluid"
						 src="../../../assets/images/Artboard 29@4x.jpg">
				</div>
				<!--      <div class="carousel-caption">-->
				<!--          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--             blanditiis quaerat porro ut,-->
				<!--             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--        </div>-->
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img alt="Random third slide" class="img-fluid"
						 src="../../../assets/images/Artboard 30@4x.jpg">
				</div>
				<!--      <div class="carousel-caption">-->
				<!--          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus id impedit atque nisi error,-->
				<!--             blanditiis quaerat porro ut,-->
				<!--             esse aspernatur quos exercitationem obcaecati quia tempore laboriosam suscipit. Quia, mollitia consequuntur.</p>-->
				<!--        </div>-->
			</ng-template>
			<!-- <ol class="carousel-indicators">
			  <li id="ngb-slide-0" class=""></li>
			  <li id="ngb-slide-1" class=""></li>
			  <li id="ngb-slide-2" class="active"></li>
			</ol> -->
		</ngb-carousel>

	</div>


	<div class="col-md-7 col-sm-12" style="background: #F0F5F8;">
		<div class="d-flex justify-content-center mx-auto" style="height: 40px; margin-top: 100px;">
			<img alt="Roadcast Logo" height="50px" [src]="constantsService.logoURL" (error)="updateLogoURL($event)">
		</div>
		<form #form="ngForm" (ngSubmit)="requestPass()" aria-labelledby="title"
			  style="display: flex;justify-content: center;align-items: center;margin-top: 50px;">
			<div class="col-md-4 col-sm-12">
				<h4 class="title" id="title">Forgot Password</h4>
				<p class="sub-title">Enter your email address and we’ll send a link to reset your password</p>

				<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
					<p class="alert-title"><b>Oh snap!</b></p>
					<ul class="alert-message-list" style="list-style-type: none; padding-left: 0px;">
						<li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
					</ul>
				</nb-alert>

				<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
					<p class="alert-title"><b>Hooray!</b></p>
					<ul class="alert-message-list" style="list-style-type: none; padding-left: 0px;">
						<li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
					</ul>
				</nb-alert>
				<div class="form-group">
					<label for="input-email">Enter your email address:</label>
					<input #email="ngModel"
						   [(ngModel)]="user.email"
						   [attr.aria-invalid]="email.invalid && email.touched ? true : null"
						   [required]="getConfigValue('forms.validation.email.required')"
						   [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
						   autofocus
						   fieldSize="medium"
						   fullWidth
						   id="input-email"
						   name="email"
						   nbInput
						   pattern=".+@.+\..+"
						   placeholder="Email address"
						   shape="semi-round">
					<ng-container *ngIf="email.invalid && email.touched">
						<p *ngIf="email.errors?.required" class="caption status-danger">
							Email is required!
						</p>
						<p *ngIf="email.errors?.pattern" class="caption status-danger">
							Email should be the real one!
						</p>
					</ng-container>
				</div>

				<button [class.btn-pulse]="submitted"
						[disabled]="submitted || !form.valid"
						fullWidth
						nbButton
						shape="semi-round"
						size="small"
						status="primary">
					Request password
				</button>
				<br> <br>
				<section aria-label="Sign in or sign up" class="sign-in-or-up" style="text-align: center;">
					<p><a class="text-link font-weight-bold" routerLink="../login">Back to Log In</a></p>
					<p><a class="text-link font-weight-bold" routerLink="../register">Register</a></p>
				</section>
			</div>
		</form>


	</div>
</div>
