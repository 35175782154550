import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Category} from '../../../@core/models';

@Component({
	selector: 'ngx-category-selection',
	templateUrl: './category-selection.component.html',
	styleUrls: ['./category-selection.component.scss'],
})
export class CategorySelectionComponent implements OnInit {

	@Input() serial: string;
	@Input() userId: string;
	@Input() selectedCategory: Category[] = [];

	categories: Promise<any>;

	constructor(private http: DataService, private toast: ToastService) {
	}

	ngOnInit() {
		this.getCategories();
	}


	getCategories() {
		this.categories = this.http.query({__active__bool: true}, 'category', 'auth');
	}

    checkStatus(id: string): boolean {
		return this.selectedCategory ? this.selectedCategory.findIndex(c => c.id === id) > -1 : false;
	}

	async changeStatus(id: number, event: any) {
		try {
			await this.http.create({__action: event ? 'add' : 'remove', category_id: id, rider_id: this.userId}, {},
				'rider_category', 'auth');
			this.toast.showToast('Successfully ' + event ? 'added' : 'removed' + ' category', 'Success', false);
		} catch (e) {
			this.toast.showToast('Error adding category ',
				'Error', true, e);
		}

	}
}
