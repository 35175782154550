import {Component, OnInit} from '@angular/core';
import {Address} from '../../../../../@core/models';
import {DataService} from '../../../../../@core/utils';
import {Column} from '../../../../../@theme/components/data-table/data-table.component';

@Component({
	selector: 'ngx-customer-info-dialog',
	templateUrl: './customer-info-dialog.component.html',
	styleUrls: ['./customer-info-dialog.component.scss'],
})
export class CustomerInfoDialogComponent implements OnInit {
	columns: Column[] =
		[{
			name: 'address',
			displayName: 'Address',
		},
			{
				name: 'google_address',
				displayName: 'Google Address',
			},
			{
				name: 'geom',
				displayName: 'Latitude',
				displayFn: (r, c) => r ? r.geom.latitude : '',
			},
			{
				name: 'geom',
				displayName: 'Longitude',
				displayFn: (r, c) => r ? r.geom.longitude : '',
			},
		];
	customerId: string;
	addresses: Address[] = [];

	constructor(private http: DataService) {

	}

	async ngOnInit() {
		this.addresses = (await this.http.query({__customer_id__equal: this.customerId}, 'address', 'auth')).data;
	}

}
