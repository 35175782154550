<nb-card>

	<nb-card-header>Permissions (Must Re-login the user for permission changes to take affect)</nb-card-header>

	<nb-card-body>
		<ng-container *ngFor="let perm of permissions">
			<div class="p-2 bg-light">
				<nb-checkbox (checkedChange)="addPermission($event, perm.id)" [checked]="checkPermission(perm.id)"
							 [status]="'primary'">
					{{perm.name}}
				</nb-checkbox>

			</div>
			<div class="row my-2 px-2">
				<div class="col-3">
					<nb-checkbox (checkedChange)="addPermission($event, perm.id, 'read')"
								 [checked]="checkPermission(perm.id, 'read')"
								 [disabled]="ifNotPermission(perm.id)"
								 [status]="'info'">
						READ
					</nb-checkbox>
				</div>
				<div class="col-3">
					<nb-checkbox (checkedChange)="addPermission($event, perm.id, 'create')"
								 [checked]="checkPermission(perm.id, 'create')"
								 [disabled]="ifNotPermission(perm.id)"
								 [status]="'info'">CREATE
					</nb-checkbox>
				</div>
				<div class="col-3">
					<nb-checkbox (checkedChange)="addPermission($event, perm.id, 'update')"
								 [checked]="checkPermission(perm.id, 'update')"
								 [disabled]="ifNotPermission(perm.id)"
								 [status]="'info'">UPDATE
					</nb-checkbox>
				</div>
				<div class="col-3">
					<nb-checkbox (checkedChange)="addPermission($event, perm.id, 'delete')"
								 [checked]="checkPermission(perm.id, 'delete')"
								 [disabled]="ifNotPermission(perm.id)"
								 [status]="'info'">DELETE
					</nb-checkbox>
				</div>
			</div>
		</ng-container>
	</nb-card-body>

</nb-card>
