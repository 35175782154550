import {Injectable, OnDestroy} from '@angular/core';
import {interval, Subject, Subscription} from 'rxjs';
import * as moment from 'moment';
import {DataService} from './data.service';

@Injectable()
export class OrderService implements OnDestroy {

	orders$: Subject<{ orders: any[], reset: boolean }> = new Subject() as Subject<{ orders: any[], reset: boolean }>;
	lastUpdatedOn: Date = new Date();
	sub: Subscription;
	private initial = true;
	today: Date = new Date();
	query: any = {};
	orderList: any[] = [];
	serviceLoading = false;

	constructor(private http: DataService) {
		this.today.setHours(this.today.getHours() - 124);
	}

	resetTime() {
		if (this.query.hasOwnProperty('__created_on__datetime_gte')) {
			this.lastUpdatedOn = moment(this.query['__created_on__datetime_gte']).utc().toDate();
		} else {
		     moment(this.lastUpdatedOn.setHours(0, 0, 10)).toJSON();
		}
	}

	async init(query?: any, reset?: boolean) {
		if (query) {
			this.query = query;
		} else {
			this.query = {};
		}
		this.resetTime();
		this.destroy();
		await this.getOrders(reset);
		this.sub = interval(15000)
			.subscribe(async () => {
				await this.getOrders();
			});
	}

	async getOrders(reset?: boolean) {
		const currentTime: Date = new Date();
		currentTime.setMinutes(currentTime.getMinutes() - 1);
		try {
			const query = {
				last_updated_on:  this.lastUpdatedOn.toJSON(),
				__only: ['id', 'external_id', 'created_on', 'updated_on', 'order_saved', 'order_undeliver', 'jfl_status_name',
					'customer', 'outlet', 'delivery_address', 'rider', 'rider_id', 'is_acknowledged', 'rider_count', 'bulk_serial',
					'status_code', 'status_name', 'order_number', 'tag', 'is_express', 'store_id', 'show_tip', 'tip_amount', 'type',
                    'payment_type', 'sub_total', 'zone_name', 'scheduled_for', 'assignment_retry', 'late_min_duration', 'is_cancelled' ],
			    __order_by: '-created_on',
			};

			for (const queryKey in this.query) {
				if (this.query.hasOwnProperty(queryKey)) {
					query[queryKey] = this.query[queryKey];
				}
			}
			const res = await this.http.query(query, 'order_redis', 'order');
			this.lastUpdatedOn = currentTime;
			this.initial = false;
			this.orders$.next({orders: res.orders, reset: reset});
			return;
		} catch (e) {
			console.error(e);
		}
	}

	setOrderList(list) {
		this.orderList = list;
	}

	destroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	ngOnDestroy(): void {
		this.destroy();
	}

}
