import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../../@core/utils';
import {interval, Subscription} from 'rxjs';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-celery-task-status-dialog',
  templateUrl: './celery-task-status-dialog.component.html',
  styleUrls: ['./celery-task-status-dialog.component.scss'],
})
export class CeleryTaskStatusDialogComponent implements OnInit, OnDestroy {

  @Input()
  checkIntervalMS = 5000;

  @Input()
  taskId: any;

  @Input()
  title = '';

  @Input()
  allowClose = false;

  dataList: any[] = [];
  sub: Subscription;
  status = 'PENDING';
  url: string = '';

  // 'SUCCESS' or 'FAILURE'
  private taskResultData: any;
  constructor(private http: DataService, public ref: NbDialogRef<CeleryTaskStatusDialogComponent>) { }

  ngOnInit() {
    this.checkAndInitSubscription().then();
  }

  async checkAndInitSubscription() {
    await this.getTaskStatus();

    if (this.status === 'PENDING') {
      this.sub = interval(this.checkIntervalMS)
          .subscribe(() => {
            this.getTaskStatus().then();
          });
    }
  }

  async getTaskStatus() {
    try {
      const taskResult = (await this.http.query({task_id : this.taskId}, 'task_status', 'auth'));

      this.taskResultData = taskResult;
      if (Array.isArray(taskResult['data'])) {
        this.dataList = taskResult['data'];
      } else {
        this.url = taskResult['data'];
      }
      this.status = taskResult['state'];

      if (this.status !== 'PENDING') {
        if (this.sub) {
          this.sub.unsubscribe();
        }
      }
    } catch (e) {
      console.error(e);
      this.taskResultData = e.error;
      this.status = 'FAILURE';
      if (this.sub) {
        this.sub.unsubscribe();
      }
    }
  }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

  closeDialog(ref: NbDialogRef<CeleryTaskStatusDialogComponent>) {
    ref.close(this.taskResultData);
  }
}
