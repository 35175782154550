import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/storage';
import {ToastService} from '../../../@core/utils';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
	selector: 'ngx-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
})
export class FileUploadComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() url: string;
	@Input() type: string;
	@Input() mandatory: boolean = false;
	@Output() getUrl: EventEmitter<string> = new EventEmitter();
	image: any;
	loader: boolean = false;
	constructor(private storage: AngularFireStorage, private toaster: ToastService,
				private cd: ChangeDetectorRef) {

	}

	ngOnInit() {
	}

	ngAfterViewInit(): void {

	}

	ngOnChanges(changes: SimpleChanges) {
		for (const prop in changes) {
			if (prop === 'mandatory') {
				this.mandatory = changes[prop].currentValue;
			}
		}
		this.cd.detectChanges();
	}

	create_UUID() {
		let dt = new Date().getTime();
		return 'xxxyxyxxyxxx'.replace(/[xy]/g, c => {
			// tslint:disable-next-line:no-bitwise
			const r = (dt + Math.random() * 16) % 16 | 0;
			dt = Math.floor(dt / 16);
			// tslint:disable-next-line:no-bitwise
			return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	}

	uploadFile(event) {
		const file: File = event.target.files[0];
		const re = '.' + file.name.split('.').pop().toString();
		let filePath = this.create_UUID();
		filePath = filePath.concat(re);
		// console.log(filePath);
		this.loader = true;
		if (this.validateFile(filePath)) {
			const ref = this.storage.ref(filePath);
			const task = ref.put(file);
			task.then(() => {
                this.url = 'https://firebasestorage.googleapis.com/v0/b/synco-dc96f.appspot.com/o/' + filePath + '?alt=media';
				this.getUrl.emit(this.url);
				this.loader = false;
			});
		} else {
			this.toaster.showToast('Not a valid file for upload. (Only png, jpg, jpeg and pdf allowed)',
				'Error', true);
			this.loader = false;
		}
	}

	validateFile(name: String) {
		const ext = name.substring(name.lastIndexOf('.') + 1);
		if (ext.toLowerCase() === 'png' || ext.toLowerCase() === 'jpg' || ext.toLowerCase() === 'jpeg'
			|| ext.toLowerCase() === 'pdf') {
			return true;
		} else {
			return false;
		}
	}
}
