import { Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-online-status',
  templateUrl: './online-status.component.html',
  styleUrls: ['./online-status.component.css'],
})
export class OnlineStatusComponent implements OnInit {
  @Input() onlineStatus: any;

  constructor() {
  }

  ngOnInit(): void {
    // console.log('online status', this.onlineStatus);
    this.onlineStatus.checkStatus.subscribe(value => {
      if (value) {
        this.hideOnlineAleart();
      }
    });

  }


  /**
   * This function used to hide the alert status of online connection;
   */
  hideOnlineAleart() {
    if (this.onlineStatus.connOnline) {
      setTimeout(() => {
        this.onlineStatus.connOnline = false;
      }, 3000);
    }
  }

}
