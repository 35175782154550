<nb-card>
	<nb-card-header>{{serial}}. Category</nb-card-header>
	<nb-card-body class="p-4">
		<ngx-type-ahead (send)="addCategory($event)" [disabled]="!rateList"
						[filters]="{__only: ['id', 'name'], __active__bool: 'true'}"
						[model]="{}" [placeholder]="'Search category here'"
						[required]="true" [url]="'category'">

		</ngx-type-ahead>

		<div class="mt-5">
			<nb-list>
				<nb-list-item *ngFor="let category of categories; let i = index">
					<div class="d-flex justify-content-between align-items-center">
						<nb-user (click)="editCategory(category.id)" [name]="category.name" class="cursor">

						</nb-user>
						<nb-icon (click)="removeCategory(category.id, i)" class="cursor" icon="close"
								 status="danger"></nb-icon>
					</div>

				</nb-list-item>
			</nb-list>
		</div>

	</nb-card-body>

	<nb-card-footer class="d-flex justify-content-end">
		<button (click)="editCategory()" nbButton shape="rectangle" status="primary">Add New</button>
	</nb-card-footer>
</nb-card>
