<nb-card>
    <div class="p-4" style="width: 400px">
        <b [innerText]="title"></b>

        <br>
        <br>
        Status: <b [innerText]="status"></b>

        <br>
        <br>
        <br>

        <nb-spinner *ngIf="status === 'PENDING'"></nb-spinner>

        <div *ngIf="status != 'PENDING' && dataList.length > 0">
            <label>Missed External Ids - ({{dataList[0].length}})</label>
            <nb-list style="max-height: 300px; overflow-y: auto">
                <nb-list-item *ngFor="let data of dataList[0]; let i = index">
                        {{data}}
                </nb-list-item>
            </nb-list>
        </div>

        <div *ngIf="status != 'PENDING' && url">
            <label>Download Report</label>
            <a href="{{url}}" download>
                <br>
                <button [shape]="'rectangle'" [status]="'info'" nbButton shape="rectangle"
                        size="small">DOWNLOAD
                </button>
            </a>
        </div>

        <br>
        <br>

        <button nbButton status="primary" *ngIf="status != 'PENDING' || allowClose" (click)="closeDialog(ref)"> Close </button>
    </div>
</nb-card>
