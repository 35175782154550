<nb-card>
	<nb-card-header>{{serial}}. Store User</nb-card-header>
	<nb-card-body class="p-4">
		<ngx-type-ahead (send)="addUser($event)"
						[basePath]="'auth'"
						[disabled]="!outletId"
						[filters]="{__only: ['id', 'name', 'mobile_number'], __is_active__bool: 'true'}" [model]="{}"
						[placeholder]="'Search merchants here'" [required]="true" [url]="'merchant'">

		</ngx-type-ahead>

		<div class="mt-5">
			<nb-list>
				<nb-list-item *ngFor="let user of users; let i = index">
					<div class="d-flex justify-content-between align-items-center">
						<nb-user (click)="editUser(user.id)" [name]="user.name" [title]="user.phone" class="cursor">

						</nb-user>
						<nb-icon (click)="removeUser(user.id, i)" class="cursor" icon="close" status="danger"></nb-icon>
					</div>

				</nb-list-item>
			</nb-list>
		</div>

	</nb-card-body>

	<nb-card-footer class="d-flex justify-content-end">
		<button (click)="editUser()" nbButton shape="rectangle" status="primary">Add New</button>
	</nb-card-footer>
</nb-card>
