<div style="height: 100vh">
	<div class="row mr-1 ml-1 topbar">
		<div class="col-md-4 col-sm-12 align-self-center">
			<!--  <div class="text-center">-->
			<img [src]="'https://res.cloudinary.com/roadcast/image/upload/v1587716970/synco/Roadcast_ehbev1.png'"
				 class="mx-auto d-block" height="45">
			<!--  </div>-->
		</div>

		<div class="col-md-4 col-sm-12 align-self-center justify-content-center d-flex">
			<div class="d-flex mx-3">
				<img height="20" src="./../../../assets/images/call.svg"/>
			</div>
			<div class="d-flex">----</div>
		</div>
	</div>


	<div class="parent">
		<div
				(leafletMapReady)="onMapReady($event)"
				[leafletOptions]="options"
				class="child"
				leaflet
				style="height: 600px">
		</div>
	</div>
	<div class="eta">
		<div class="mx-auto"
			 style="background: #28393c;width: 110px;margin: 0 auto;padding: 4px;border-radius: 4px">{{eta ? 'ETA: ' + eta + ' mins' : 'NA'}}
		</div>
	</div>
	<!--<div style="text-align: center;position: absolute;bottom: 0;background: #fff;z-index: 12222222;width:100%">-->
	<!--    <h5 style="display: inline">-->
	<!--        <strong>Name:</strong>-->
	<!--    </h5>-->
	<!--    <h6 style="display: inline">-->
	<!--        {{userName}}-->
	<!--    </h6>-->
	<!--    <h5 style="display: inline">-->
	<!--        <strong>Phone:</strong>-->
	<!--    </h5>-->
	<!--    <h6 style="display: inline">-->
	<!--        {{userPhone}}-->
	<!--    </h6><br>-->
	<!--    <h5 style="display: inline">-->
	<!--        <strong>Destination:</strong>-->
	<!--    </h5>-->
	<!--    <h6 style="display: inline">-->
	<!--        {{destinationAddress}}-->
	<!--    </h6>-->
	<!--</div>-->


	<div class="secondBottom">
		<div class="col-4"><img height="70" src="./../../../assets/images/trip-user.svg"></div>
		<div class="col-8 align-self-center">
			<div style="display: flex;justify-content: space-between;">
				<div class="userName">{{userName ? userName + ' ' : 'Not available'}}</div>
				<div class="textBottom"> {{userPhone ? '+971-' + userPhone : 'Not available'}}
				</div>
			</div>
			<div style="display: flex;margin-top: 8px;">is on his way to deliver your order</div>
		</div>
	</div>
	<div class="bottombar">
		<div>{{outletName ? outletName : 'Not available'}}</div>
	</div>
</div>
