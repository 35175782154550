<div [nbSpinnerMessage]="'Uploading File'" [nbSpinnerStatus]="'danger'" [nbSpinner]="loader" class="input-group">
	<input
		(change)="uploadFile($event)" [(ngModel)]="image" [placeholder]="url"
		[required]="mandatory && !url"
		class="form-control w-auto h-100" name="image" nbInput
		shape="rectangle" style="max-width: inherit" type="file">
	<div class="input-group-append">
		<span class="input-group-text bg-white" id="basic-addon2">
			<a *ngIf="url" [href]="url" target="_blank">VIEW</a>
		</span>
	</div>
</div>
