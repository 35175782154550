<mbsc-datepicker
        [selectMultiple]="selectMultiple"
        [inputStyle]="inputStyle"
        [controls]="controls"
        [(ngModel)]="selected"
        [dateFormat]="dateFormat"
        (onChange)="selectDateRange($event)"
        [placeholder]="placeholder"
        [touchUi]="true"
        [select]="select"
        [display]="display"
        [max]="maxDate"
        [min]="minDate">
</mbsc-datepicker>
