import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Outlet} from '../../../@core/models';
import {Router} from '@angular/router';

@Component({
	selector: 'ngx-outlet-list',
	templateUrl: './outlet-list.component.html',
	styleUrls: ['./outlet-list.component.scss'],
})
export class OutletListComponent implements OnInit, AfterViewInit, OnChanges {

	@Input()
	serial: string = '1';
	@Input()
	id: string;
	@Input()
	type: string;

	filters: any = {__only: ['id', 'name'], __active__bool: 'true'};
	page: number = 1;
	outlets: Outlet[] = [];

	constructor(private http: DataService, private toaster: ToastService, private router: Router) {
	}

	ngOnInit() {
		this.filters.__brand_id__ne = this.id;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('id') && changes.id.currentValue) {
			this.getOutlets().then();
		}
	}

	ngAfterViewInit(): void {
		this.filters['__' + this.type + '__ne'] = this.id;
		if (this.id) {
			this.getOutlets().then();
		}
	}


	async getOutlets() {
		try {
			const query = {__page: this.page, __only: ['id', 'name', 'brand']};
			query['__' + this.type + '__equal'] = this.id;
			this.outlets = (await this.http.query(query, 'outlet', 'auth')).data;
		} catch (e) {
		}
	}

	async addOutlet(event) {
		if (this.type !== 'merchant_id') {
			const data = {};
			data[this.type] = this.id;
			try {
				await this.http.update(event.id, data, {}, 'outlet', 'auth');
				this.outlets.push(event);
				this.toaster.showToast('Successfully added outlet.', 'Success', false);
			} catch (e) {
				this.toaster.showToast('Error adding outlet ', 'Error', true, e);
			}
		} else {
			const data = {outlet_id: event.id, merchant_id: this.id, __action: 'add'};
			try {
				await this.http.create(data, {}, 'merchant_outlet_association', 'auth');
				this.outlets.push(event);
				this.toaster.showToast('Successfully added outlet.', 'Success', false);
			} catch (e) {
				this.toaster.showToast('Error adding outlet ', 'Error', true, e);
			}
		}

	}


	async removeOutlet(id: number, index: number) {
		if (this.type !== 'merchant_id') {
			const data = {};
			data[this.type] = null;
			try {
				await this.http.update(id, data, {}, 'outlet', 'auth');
				this.outlets.splice(index, 1);
				this.toaster.showToast('Successfully removed outlet.', 'Success', false);
			} catch (e) {
				this.toaster.showToast('Error removing outlet ', 'Error', true, e);
			}
		} else {
			const data = {outlet_id: id, merchant_id: this.id, __action: 'remove'};
			try {
				await this.http.create(data, {}, 'merchant_outlet_association', 'auth');
				this.outlets.splice(index, 1);
				this.toaster.showToast('Successfully added outlet.', 'Success', false);
			} catch (e) {
				this.toaster.showToast('Error adding outlet ', 'Error', true, e);
			}
		}
	}

	editOutlet(id?: number) {
		this.router.navigate(['/pages/management/base/outlet/' + (id ? id.toString(10) : 'new')]);
	}

	prevPage() {
		this.page -= 1;
		this.getOutlets().then();
	}

	nextPage() {
		this.page += 1;
		this.getOutlets().then();
	}
}
