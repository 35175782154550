import {AfterViewInit, ChangeDetectorRef, Component, Input} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';

@Component({
	selector: 'ngx-user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements AfterViewInit {

	@Input()
	serial: string = '1';
	@Input()
	zoneId: string;

	users: any[] = [];
	filters: any;
	infoPopup = 'If unable to add users, create zone first (step 1)';

	constructor(private http: DataService, private toaster: ToastService, private router: Router, private cd: ChangeDetectorRef) {
	}



	ngAfterViewInit(): void {
		this.filters = {__only: ['id', 'name'], __zone_id__ne: this.zoneId, __active__bool: true};
		if (this.zoneId) {
			this.getUsers().then();
		}
		this.cd.detectChanges();
	}

	async getUsers() {

		try {
			const query = {
				__only: ['id', 'name'], __zone_id__equal: this.zoneId,
				user: true, __limit: 100,
			};
			const users = await this.http.query(query, 'user', 'auth');
			if (users.data && users.data.length) {
				this.users = users.data;
			} else {
				this.users = [];
			}
		} catch (e) {
		}

	}

	editUser(id?: number) {
		this.router.navigate(['/pages/management/base/user/' + (id ? id : 'new')]);
	}

	async addUser(event) {
		this.cd.detectChanges();

		try {
			await this.http.create({
				__action: 'add',
				user_id: event.id,
				zone_id: this.zoneId,
			}, {}, 'user_zone', 'auth');
			this.users.push(event);
			this.toaster.showToast('Successfully added user.', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error adding user ', 'Error', true, e);
		}


	}

	async removeUser(id: number, index: number) {

		try {
			await this.http.create({
				__action: 'remove',
				user_id: id,
				zone_id: this.zoneId,
			}, {}, 'user_zone', 'auth');
			this.users.splice(index, 1);
			this.toaster.showToast('Successfully removed user.', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error removing user ', 'Error', true, e);
		}
	}
}
