import {Component, Input, OnInit} from '@angular/core';
import {DataService, ToastService} from '../../../@core/utils';
import {Router} from '@angular/router';

@Component({
	selector: 'ngx-branch-list',
	templateUrl: './branch-list.component.html',
	styleUrls: ['./branch-list.component.scss'],
})
export class BranchListComponent implements OnInit {
	@Input()
	serial: string = '1';
	@Input()
	branches: any[] = [];
	@Input()
	userId: number;

	constructor(private http: DataService, private toaster: ToastService, private router: Router) {
	}

	ngOnInit() {
	}

	async addBranch(event) {
		try {
			await this.http.create({
				__action: 'add',
				branch_id: event.id,
				user_id: this.userId,
			}, {}, 'user_branch_association');
			this.branches.push(event);
			this.toaster.showToast('Successfully create branch.', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error removing branch ', 'Error', true, e);
		}
	}

	async removeBranch(id: number, index: number) {
		try {
			await this.http.create({
				__action: 'remove',
				branch_id: id,
				user_id: this.userId
			}, {}, 'user_branch_association');
			this.branches.splice(index, 1);
			this.toaster.showToast('Successfully removed branch.', 'Success', false);
		} catch (e) {
			this.toaster.showToast('Error removing branch ', 'Error', true, e);
		}
	}

	editBranch(id?: number) {
		this.router.navigate(['/pages/management/base/branch/' + (id ? id.toString(10) : 'new')]);
	}

}
